/*
Camera Manager class
*/

import * as THREE from "three";
// import SimplexNoise from 'simplex-noise';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
// import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';

// npm postprocessing includes blur effects 
import { KawaseBlurPass, SelectiveBloomEffect, EffectComposer, EffectPass, RenderPass } from "postprocessing";

import playfolio from "@/assets/data/playfolio";

import gsap from "gsap";

class CameraManager {
    /**
     * Constructor
     * @param {*} cameraParams 
     * @param cameraParams.currentSketch The current experiment
     * @param cameraParams.scene The threejs scene
     * @param cameraParams.renderer The threejs WebGL renderer
     * @param cameraParams.width The view's width
     * @param cameraParams.height The view's height
     */
    constructor(cameraParams) {
        this.currentActivation = cameraParams.currentSketch;

        this.simulationScene = cameraParams.scene;
        this.renderer = cameraParams.renderer;

        this.width = cameraParams.width;
        this.height = cameraParams.height;

        this.activeCamera = null;

        // Cameras
        this.orthoCamera = null;
        this.orthoZoomLevel = 0.4;

        this.perspectiveCamera = null;
        this.playParams = playfolio.items.filter(item => item.title === this.currentActivation)[0].threeParams;

        this.defaultPosition = this.playParams.namedViews["HERO VIEW"].position;
        this.defaultTarget = this.playParams.namedViews["HERO VIEW"].target;

        this.camControls = null;

        // Postprocessing
        this.perspectiveComposer = null;
        this.orthoComposer = null;

        // Bloom parameters
        this.orthoBloomParams = {
            luminanceThreshold: 0.2,
            luminanceSmoothing: 0.025,
            intensity: 8.0,
            radius: 0.4, //0.85,
            blur: 0.1,
        };
        this.perspBloomParams = {
            luminanceThreshold: 0.65,
            luminanceSmoothing: 0.025,
            intensity: 1.70,
            radius: 0.12, //0.85,
            blur: 0.3,
        };
        this.blurPass = null;

        this.initCameras();

        this.activeCamera = this.orthoCamera;

        // Add postprocessing
        [this.perspectiveComposer, this.perspBloomPass] = this.createEffectsPostprocessing(this.perspectiveCamera, this.perspBloomParams);

        [this.orthoComposer, this.orthoBloomPass] = this.createEffectsPostprocessing(this.orthoCamera, this.orthoBloomParams);

        // Flythrough
        this.flythruTimeline = null;
        // this.initFlythruTimeline();
    }

    /**
     * Get the currently active camera (ortho or perspective)
     */
    get camera() {
        return this.activeCamera;
    }

    get perspectiveBloomPass() {
        return this.perspBloomPass;
    }

    /**
     * Render ortho/perspective views
     */
    renderOrtho() {
        this.orthoComposer.render();
    }

    renderPerspective() {
        this.perspectiveComposer.render();
    }

    setActiveCamera(newCam) {
        console.log("[cam mgmt] new camera ", newCam);
        switch (newCam) {
            case "ORTHO":
                this.activeCamera = this.orthoCamera;
                break;
            case "PERSPECTIVE":
                this.perspectiveCamera.position.set(this.defaultPosition.x, this.defaultPosition.y, this.defaultPosition.z);
                this.resetControlsTarget();
                this.activeCamera = this.perspectiveCamera;
                break;
        }
    }

    onWindowResize(width, height) {
        console.log("[cam mgmt] resize")
        this.width = width;
        this.height = height;

        let aspect = this.width / this.height;
        let viewSize = this.width * this.orthoZoomLevel;

        // Update perspective camera
        this.perspectiveCamera.aspect = aspect;
        this.perspectiveCamera.updateProjectionMatrix();

        // Update ortho camera
        this.orthoCamera.aspectRatio = aspect;
        this.orthoCamera.left = (-aspect * viewSize) / 2;
        this.orthoCamera.right = (aspect * viewSize) / 2;
        this.orthoCamera.top = viewSize / 2;
        this.orthoCamera.bottom = -viewSize / 2;
        this.orthoCamera.updateProjectionMatrix();
    }

    initCameras() {
        this.createPerspectiveCamera();
        this.createCamControls();
        this.createOrthoCamera();
    }

    updateControls() {
        this.camControls.update();
    }

    /**
     * Set up a perspective camera for the simulation
     */
    createPerspectiveCamera() {
        // Create a perspective camera
        this.perspectiveCamera = new THREE.PerspectiveCamera(50, this.width / this.height, 0.1, 20000);

        this.perspectiveCamera.position.set(this.defaultPosition.x, this.defaultPosition.y, this.defaultPosition.z);
    }

    /**
     * Set up an orthographic camera for the simulation
     */
    createOrthoCamera() {
        let w = this.width;
        let h = this.height;
        let viewSize = w * this.orthoZoomLevel;
        let aspectRatio = w / h;

        let _viewport = {
            viewSize: viewSize,
            aspectRatio: aspectRatio,
            left: (-aspectRatio * viewSize) / 2,
            right: (aspectRatio * viewSize) / 2,
            top: viewSize / 2,
            bottom: -viewSize / 2,
            near: 0.1,
            far: 1000
        }

        this.orthoCamera = new THREE.OrthographicCamera(
            _viewport.left,
            _viewport.right,
            _viewport.top,
            _viewport.bottom,
            _viewport.near,
            _viewport.far
        );

        this.orthoCamera.up.set(0, 1, 0);

        // TODO:
        // Move to configuration file
        let xPos = 400;
        let zPos = -800;

        this.orthoCamera.position.set(xPos, 100, zPos);
        this.orthoCamera.lookAt(new THREE.Vector3(xPos, 400, zPos));

        this.orthoCamera.rotation.z = -Math.PI * 0.5;
    }

    /**
     * Create orbit controls
     * https://threejs.org/docs/#examples/en/controls/OrbitControls
     */
    createCamControls() {
        this.camControls = new OrbitControls(this.perspectiveCamera, this.renderer.domElement);

        this.camControls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
        this.camControls.dampingFactor = 0.05;

        this.camControls.screenSpacePanning = false;

        this.camControls.minDistance = 10;
        this.camControls.maxDistance = 20000;

        this.camControls.maxPolarAngle = Math.PI * 0.75;

        // let midIndex = parseInt(bowlPts.midpoints.length * 0.5);
        this.resetControlsTarget();
    }

    resetControlsTarget() {
        this.camControls.target = new THREE.Vector3(this.defaultTarget.x, this.defaultTarget.y, this.defaultTarget.z);

        this.camControls.update();
    }

    /**
     * Create postprocessing effects
     * @param {} camera The camera to apply effects to
     * @param {*} bloomParams The bloom parameters
     * @returns 
     */
    createEffectsPostprocessing(camera, bloomParams) {
        let composer = new EffectComposer(this.renderer, {
            multisampling: 2,
        });

        const renderScenePass = new RenderPass(this.simulationScene, camera);

        const blurPass = new KawaseBlurPass({
            height: 1080
        });

        blurPass.blurMaterial.scale = bloomParams.blur;

        // const bloomPass = new BloomEffect({
        //     luminanceThreshold: bloomParams.luminanceThreshold,
        //     luminanceSmoothing: bloomParams.luminanceSmoothing,
        //     intensity: bloomParams.intensity,
        //     radius: bloomParams.radius,
        // });

        let bloomPass = new SelectiveBloomEffect(this.simulationScene, camera, {
            mipmapBlur: true,
            luminanceThreshold: bloomParams.luminanceThreshold,
            luminanceSmoothing: bloomParams.luminanceSmoothing,
            intensity: bloomParams.intensity,
            ignoreBackground: true,
        });

        bloomPass.inverted = false;
        bloomPass.ignoreBackground = true;
        bloomPass.luminancePass.enabled = false;

        bloomPass.mipmapBlurPass.radius = bloomParams.radius;

        bloomPass.selection.clear();

        const bloomEffectPass = new EffectPass(camera, bloomPass);

        composer.addPass(renderScenePass);
        composer.addPass(bloomEffectPass);
        composer.addPass(blurPass);

        return [composer, bloomPass];
    }

    ////////////////////////////////
    // FLYTHROUGH & NAMED VIEWS
    ////////////////////////////////

    /**
     * Set a new named view without animating
     * @param {*} val 
     */
    setNamedView(val) {
        let cameraViews = this.playParams.namedViews;

        let camView = cameraViews[val];

        this.tweenView(camView, 0);
    }

    /**
     * Creates a flythrough timeline
     */
    initFlythruTimeline() {
        this.flythruTimeline = new gsap.timeline({
            ease: "power4.inOut",
            onUpdate: () => {
                this.perspectiveCamera.updateProjectionMatrix();

                this.resetControlsTarget();
            },
        });
    }

    /**
     * Animates a camera flythrough
     */
    animateFlythru() {
        if (this.currentView == "ORTHO") return;
        console.log("[camera mgmt] flythrough");

        let duration = 20;

        let cameraViews = this.playParams.namedViews;

        for (let camView of Object.entries(cameraViews)) {
            let camViewVal = camView[1];
            this.tweenView(camViewVal, duration)
        }
    }

    tweenView(camViewVal, duration) {
        this.flythruTimeline.to(this.perspectiveCamera.position, camViewVal.position).duration(duration);
        this.flythruTimeline.to(this.camControls.target, camViewVal.target).duration(duration);

        // TODO:
        // Tween FOV and controls target!

        // this.flythruTimeline.to(this.perspectiveCamera.fov, camViewVal.fov).duration(duration);
    }
}


export { CameraManager };