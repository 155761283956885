<template>
  <router-view />
</template>

<script>
import json from "@/assets/data/portfolio.json";
import productFolio from "@/assets/data/productFolio.json";

import playfolio from "@/assets/data/playfolio";

export default {
  setup() {},
  computed: {
    projects() {
      return this.$store.state.projects;
    },
    projectImages() {
      return this.$store.state.projectImages;
    },
  },
  methods: {
    loadFromPath(curPath) {
      let counter = 1;
      let imgList = [];
      while (counter < 100) {
        try {
          let img = new Image();
          let reqPath = require(`./assets/images/${curPath}${counter}.jpg`);
          if (!reqPath)
            reqPath = require(`./assets/images/${curPath}${counter}.png`);
          img.src = reqPath;
          imgList.push(img);
          counter += 1;
        } catch (e) {
          // console.log(`Cannot find image at ./assets/images/${curPath}${counter}`);
          // console.log(e);
          break;
        }
      }
      return imgList;
    },
    loadProjects() {
      let image_store = {};

      let jsonFile = JSON.parse(JSON.stringify(json));

      /**
       * Commit projects to store
       */
      let projects = jsonFile.items.filter(function (obj) {
        return obj["visible"];
      });
      this.$store.commit("setProjects", JSON.parse(JSON.stringify(projects)));

      /**
       * Parse image data and commit to store
       */
      projects.forEach((project) => {
        let curProjectTitle = project["Project Title"];
        image_store[curProjectTitle] = {};
        image_store[curProjectTitle].images = [];
        image_store[curProjectTitle].thumbnails = [];

        let curImagePath = curProjectTitle + "/";
        let curThumbnailPath = curProjectTitle + "/thumbnails/";

        image_store[curProjectTitle].images = this.loadFromPath(curImagePath);
        image_store[curProjectTitle].thumbnails = this.loadFromPath(
          curThumbnailPath,
          image_store[curProjectTitle].thumbnails
        );
      });

      this.$store.commit(
        "setProjectImages",
        image_store
      );
    },
    loadProducts() {
      let image_store = {};

      let jsonFile = JSON.parse(JSON.stringify(productFolio));

      /**
       * Commit product projects to store
       */
      let products = jsonFile.items.filter(function (obj) {
        return obj["visible"];
      });
      this.$store.commit("setProducts", JSON.parse(JSON.stringify(products)));

      /**
       * Parse image data and commit to store
       */
      products.forEach((project) => {
        let curProjectTitle = project["Project Title"];
        image_store[curProjectTitle] = {};
        image_store[curProjectTitle].images = [];
        image_store[curProjectTitle].thumbnails = [];

        let curImagePath = curProjectTitle + "/";
        let curThumbnailPath = curProjectTitle + "/thumbnails/";

        image_store[curProjectTitle].images = this.loadFromPath(curImagePath);
        image_store[curProjectTitle].thumbnails = this.loadFromPath(
          curThumbnailPath,
          image_store[curProjectTitle].thumbnails
        );
      });

      this.$store.commit(
        "setProductImages",
        image_store
      );

    },
    loadExperiments() {
      let image_store = {};

      /**
       * Commit projects to store
       */
      let experiments = playfolio.items.filter(function (obj) {
        return obj["visible"];
      });
      this.$store.commit("setExperiments", JSON.parse(JSON.stringify(experiments)));

      /**
       * Parse image data and commit to store
       */
      experiments.forEach((project) => {
        let curProjectTitle = project.title;
        image_store[curProjectTitle] = {};
        image_store[curProjectTitle].images = [];
        image_store[curProjectTitle].thumbnails = [];

        let curImagePath = curProjectTitle + "/";
        let curThumbnailPath = curProjectTitle + "/thumbnail/";

        image_store[curProjectTitle].images = this.loadFromPath(curImagePath);
        image_store[curProjectTitle].thumbnails = this.loadFromPath(
          curThumbnailPath,
          image_store[curProjectTitle].thumbnails
        );
      });

      this.$store.commit(
        "setExperimentsImages",
        image_store
      );
    },
  },
  mounted() {
    this.loadProjects();
    this.loadExperiments();
    this.loadProducts();
  },
};
</script>

<style lang="scss">
@import url("https://use.typekit.net/mle1sit.css");

@font-face {
  font-family: "PP Neue Machina";
  src: url("~@/assets/fonts/PPNeueMachina/PPNeueMachina-Regular.eot");
  src: url("~@/assets/fonts/PPNeueMachina/PPNeueMachina-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/PPNeueMachina/PPNeueMachina-Regular.woff2")
      format("woff2"),
    url("~@/assets/fonts/PPNeueMachina/PPNeueMachina-Regular.woff")
      format("woff"),
    url("~@/assets/fonts/PPNeueMachina/PPNeueMachina-Regular.ttf")
      format("truetype"),
    url("~@/assets/fonts/PPNeueMachina-Trial/PPNeueMachina-Regular.svg#PPNeueMachina-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@import "./assets/scss/_variables.scss";

a {
  color: black;
}

#app {
  font-family: "PP Neue Machina", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  cursor: require("@/assets/icons/pointer.svg") 16 16, pointer !important;
}

////////////////////////////////////////////
// TYPE
////////////////////////////////////////////

html {
  scroll-behavior: smooth;

  font-size: 12px;
  @media (min-width: 800px) {
    font-size: 14px;
  }
  @media (min-width: 1200px) {
    font-size: 16px;
  }

  overflow-x: hidden;
}

// html {
//   font-size: 16px;
//   scroll-behavior: smooth;
//   // Magic:
//   @media (max-width: 1200px) {
//     font-size: calc(12px + .4vw);
//   }
// }

h2 {
  font-family: "PP Neue Machina", Helvetica, Arial, sans-serif !important;
  font-size: 1.563rem !important;
  text-align: left !important;
}

h3 {
  font-family: "PP Neue Machina", Helvetica, Arial, sans-serif !important;
  font-size: 1.25rem !important;
  font-weight: 500 !important;
  text-align: left !important;
  // font-size: 1rem !important;
}

h4 {
  font-family: "PP Neue Machina", sans-serif !important;
  // font-family: "neue-haas-grotesk-text", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 0.95rem !important;
  letter-spacing: 0.004rem;
  text-align: left !important;
}

h5 {
  font-family: "PP Neue Machina", sans-serif !important;
  // font-family: "neue-haas-grotesk-text", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 0.8rem !important;
  line-height: 0.9rem !important;
  text-align: left !important;
}

.portfolio-item {
  border-top: 1px solid black;
}

////////////////////////////////////////////
// BUTTONS
////////////////////////////////////////////

.btn {
  background-color: white !important;
  border-color: black !important;
  border-radius: 1rem !important;
  height: 1.5rem !important;
  line-height: 0.9rem !important;
  font-size: 1rem !important;
  text-align: center !important;
  // margin-top: 0px !important;
  // padding: 0.25rem 0.5rem 0.25rem 0.5rem !important;
  // width: auto !important;
  text-transform: uppercase !important;
  // margin: auto !important;
  border-width: thin !important;
  vertical-align: middle;
}

.btn:hover {
  background-color: $button-black !important;
  color: white !important;
}

button {
  vertical-align: top !important;
  // display: inline-block !important;
  margin-top: 0px !important;
  margin-right: 0 !important;
  right: 0;
  float: right;
}



////////////////////////////////////////////
// FORMS
////////////////////////////////////////////
input {
  border: none !important;
  border-bottom: 1px black solid !important;
  width: 100% !important;
  font-size: 1rem !important;
  line-height: 1.25rem !important;
}

input:focus,
input:focus-visible {
  // border: none !important;
  outline: none !important;
  border-bottom: 1px black solid !important;
}

////////////////////////////////////////////
// INLINE LINKS
////////////////////////////////////////////

.inline-link {
  cursor: var(--pointerCursor);
  position: relative;
}

.inline-link:hover {
  text-decoration: none;
}

.inline-link-wavy {
  text-decoration: wavy underline 1px !important;
  cursor: var(--pointerCursor);
}

.inline-link::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #000;
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.3s ease;
}

.inline-link:hover::before {
  transform: scaleX(1);
}

.inline-link-wavy:hover {
  // font-weight: bold;
  font-style: italic;
  text-decoration: wavy underline 1px !important;
}

.play-btn {
  background-color: #f8f5f1 !important;
}
</style>
