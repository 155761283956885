<template>
    <div class="play-background-container p-0 m-0 w-100 h-100" :style="cssProps" @click="onBackgroundClicked()">
    </div>
</template>

<script>
export default {
  name: "PlayBackground",
  props: [],
  data: function () {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    };
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
    onBackgroundClicked() {
      this.$store.commit("togglePopup");
    }
  },
  computed: {
    cssProps() {
      return {
        "--width": this.width + "px",
        "--height": this.height + "px",
      }
    }
  },
  created() {
  },
  unmounted() {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./../assets/scss/_variables.scss";

.play-background-container {
    background-color: #ECE9E2;

    background: #ECE9E2 center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    position: absolute;
    width: var(--width);
    height: var(--height);

    z-index: 0;

    overflow: hidden;
    overflow-x: hidden;
}

</style>