export default {
    "items": [
        {
            title: "Hybrid Objects Brand",
            visible: true,
            id: "2023.3-1",
            category: "Brand identity",
            year: 2023,
            abstract: "A hybrid identity for Hybrid Objects.",
            isCode: false,
            info: {
                type: "Brand sketches",
                title: "Hybrid Objects Brand Sketches",
                "shortDescription": "A hybrid identity for <a href=https://hybrid-objects.com class='inline-link-wavy' target='_blank'>Hybrid Objects</a>.",
                "longDescription": "I've been experimenting with defining a playful identity for my fabrication-oriented, collective creative practice, Hybrid Objects. Hybrid Objects ponders about the material implications of digital design processes.<br><br>Below, the Hybrid Objects identity is anchored by a playful logo that not only merges the Latin letters 'H' and 'O' but also nods to the Korean 호, collapsing ㅎ and ㅗ. This typographic double hybrid captures the studio's design across scales, disciplines, and cultures. Animated in a range of materials, the dynamic simulations encapsulate Hybrid Objects' translations between physical and digital materiality.",
                "collaborators": "",
                "sections": [
                    {
                        "title": "",
                        "description": "",
                        "media": [
                            {
                                type: "image",
                                url: require("../../assets/images/Hybrid Objects Brand/9.jpg"),
                            },
                            {
                                type: "video",
                                url: "https://player.vimeo.com/video/818951417?h=1db642e197&autoplay=1&controls=0&loop=1&h=3412c364a3&badge=0&autopause=0&player_id=0&app_id=58479",
                                dimensions: [1280, 720]
                            },
                            {
                                type: "video",
                                url: "https://player.vimeo.com/video/816692071?h=bb13b246d1&autoplay=1&controls=0&loop=1&h=3412c364a3&badge=0&autopause=0&player_id=0&app_id=58479",
                                dimensions: [1920, 1080]
                            },
                            
                            {
                                type: "image",
                                url: require("../../assets/images/Hybrid Objects Brand/6.jpg"),
                            },
                            {
                                type: "image",
                                url: require("../../assets/images/Hybrid Objects Brand/7.jpg"),
                            },
                            {
                                type: "image",
                                url: require("../../assets/images/Hybrid Objects Brand/8.jpg"),
                            },
                        ]
                    },
                    {
                        "title": "Earlier sketches",
                        "description": "Playful physics interactions in earlier sketches explored the friction between actual and virtual physics to prop up, inflate, balance, and glue the squeezing letter bodies.",
                        "media": [
                            {
                                type: "video",
                                url: "https://player.vimeo.com/video/818520500?h=9f5bfe0d25&autoplay=1&controls=0&loop=1&h=3412c364a3&badge=0&autopause=0&player_id=0&app_id=58479",
                                dimensions: [1920, 1080]
                            },
                            {
                                type: "image",
                                url: require("../../assets/images/Hybrid Objects Brand/3.jpg"),
                            },
                            {
                                type: "image",
                                url: require("../../assets/images/Hybrid Objects Brand/1.jpg"),
                            },
                            {
                                type: "image",
                                url: require("../../assets/images/Hybrid Objects Brand/4.jpg"),
                            }
                        ]
                    },
                ]
            },
            videos: [
                {
                    url: "https://player.vimeo.com/video/818951417?h=1db642e197&autoplay=1&controls=0&loop=1&h=3412c364a3&badge=0&autopause=0&player_id=0&app_id=58479",
                    dimensions: [1920, 1080]
                },
                {
                    url: "https://player.vimeo.com/video/816692071?h=bb13b246d1&autoplay=1&controls=0&loop=1&h=3412c364a3&badge=0&autopause=0&player_id=0&app_id=58479",
                    dimensions: [1920, 1080]
                }
            ]
        },
        {
            title: "Plastic Futures",
            visible: true,
            id: "2023.3-2",
            category: "Motion",
            year: 2023,
            isCode: false,
            abstract: "",
            info: {
                type: "3D Motion sketch",
                title: "Plastic Futures",
                "shortDescription": "Reflections on our relationship with plastics and the search for sustainable alternatives.",
                "longDescription": "Plastics have become an integral part of our daily lives, but their impact on the environment is undeniable. They persist in our oceans and litter our landscapes, reminding us of the consequences of our dependence on these durable yet harmful materials. This project explores our complex relationship with plastics to meditate on the challenges and opportunities associated with finding alternatives. Rather than proposing solutions, the project seeks to foster dialogue and generate a deeper understanding of the social, economic, and environmental factors that drive our use of plastics.",
                "collaborators": "",
                "sections": [
                    {
                        "title": "",
                        "description": "",
                        "media": [
                            {
                                url: "https://player.vimeo.com/video/819275171?h=0829026367&autoplay=1&controls=0&loop=1&h=3412c364a3&badge=0&autopause=0&player_id=0&app_id=58479",
                                dimensions: [1920, 1080]
                            },
                            {
                                type: "image",
                                url: require("../../assets/images/Plastic Futures/2.jpg"),
                            },
                            {
                                type: "image",
                                url: require("../../assets/images/Plastic Futures/3.jpg"),
                            },
                            {
                                type: "image",
                                url: require("../../assets/images/Plastic Futures/4.jpg"),
                            },
                            {
                                type: "image",
                                url: require("../../assets/images/Plastic Futures/1.jpg"),
                            },
                            {
                                type: "video",
                                url: "https://player.vimeo.com/video/819241323?h=c2a38b1116&autoplay=1&quality=1080p&controls=0&loop=1&h=3412c364a3&badge=0&autopause=0&player_id=0&app_id=58479",
                                dimensions: [1920, 1080]
                            },
                            
                            
                        ]
                    }
                ]
            },
            videos: [
                {
                    url: "https://player.vimeo.com/video/819275171?h=0829026367&autoplay=1&controls=0&loop=1&h=3412c364a3&badge=0&autopause=0&player_id=0&app_id=58479",
                    dimensions: [1920, 1080]
                },
                {
                    url: "https://player.vimeo.com/video/816688311?h=7f0ecdffdf&autoplay=1&controls=0&loop=1&h=3412c364a3&badge=0&autopause=0&player_id=0&app_id=58479",
                    dimensions: [1080, 1080]
                }
            ]
        },
        {
            title: "Pillow Flight",
            visible: true,
            id: "2023.3-2",
            category: "Motion",
            year: 2023,
            isCode: false,
            info: {
                type: "3D Motion sketch",
                title: "Pillow Flight",
                "shortDescription": "A whimsical dance by canvas pillows falling on a chair.",
                "longDescription": "<i>Pillow Flight</i> is a playful 3D animation featuring a chair with canvas tube pillows. The animation begins with the pillows suspended in mid-air above the chair, and as the camera zooms out, the pillows start to fall. The pillows tumble and swirl, creating a colorful and whimsical display as they move in a synchronized choreography.",
                "collaborators": "",
                "sections": [
                    {
                        "title": "",
                        "description": "",
                        "media": [
                            {
                                type: "video",
                                url: "https://player.vimeo.com/video/818017919?h=b6d8f40892&autoplay=1&controls=0&loop=1&h=3412c364a3&badge=0&autopause=0&player_id=0&app_id=58479",
                                dimensions: [1920, 1080]
                            },
                            
                           
                        ]
                    },
                    {
                        "title": "Earlier sketches",
                        "description": "",
                        "media": [
                            {
                                type: "image",
                                url: require("../../assets/images/Pillow Flight/1.jpg"),
                            },
                            {
                                type: "image",
                                url: require("../../assets/images/Pillow Flight/2.jpg"),
                            },
                            {
                                type: "video",
                                url: "https://player.vimeo.com/video/818014120?h=27797bb50e&autoplay=1&controls=0&loop=1&h=3412c364a3&badge=0&autopause=0&player_id=0&app_id=58479",
                                dimensions: [1920, 1080]
                            },
                        ]
                    },
                ]
            },
            videos: [
                {
                    url: "https://player.vimeo.com/video/817997684?h=8784eed37f&autoplay=1&controls=0&loop=1&h=3412c364a3&badge=0&autopause=0&player_id=0&app_id=58479",
                    dimensions: [1920, 1080]
                },
                {
                    url: "https://player.vimeo.com/video/818014120?h=27797bb50e&autoplay=1&controls=0&loop=1&h=3412c364a3&badge=0&autopause=0&player_id=0&app_id=58479",
                    dimensions: [1920, 1080]
                },
                {
                    url: "https://player.vimeo.com/video/818017919?h=b6d8f40892&autoplay=1&controls=0&loop=1&h=3412c364a3&badge=0&autopause=0&player_id=0&app_id=58479",
                    dimensions: [1920, 1080]
                }
            ]
        },
        {
            title: "Soft Serif",
            visible: true,
            id: "2023.3-3",
            category: "Code Sandbox",
            year: 2023,
            abstract: "A hybrid identity for Hybrid Objects.",
            isCode: true,
            isThree: true,
            info: {
                type: "WebGL sketch",
                title: "Soft Serif",
                "shortDescription": "An interactive soft body collider Hybrid Objects brand sketch based on <a href=https://threejs.org/examples/?q=ammo#physics_ammo_volume class='inline-link-wavy' target='_blank'>this three.js example</a>.",
                tools: ["matter.js", "threejs"],
                "collaborators": "",
                "sections": [
                    {
                        "title": "Final sketches",
                        "description": "The piece is perched by the fountain at the end of the Osborne Garden, the first space that visitors stroll through upon entering the gardens via the Eastern Parkway entrance.",
                        "media": [
                            {
                                type: "video",
                                url: "https://player.vimeo.com/video/816692071?h=bb13b246d1&autoplay=1&controls=0&loop=1&h=3412c364a3&badge=0&autopause=0&player_id=0&app_id=58479",
                                dimensions: [1920, 1080]
                            },
                        ]
                    },
                    {
                        "title": "Earlier sketches",
                        "description": "",
                        "media": [
                            {
                                type: "image",
                                url: require("../../assets/images/Hybrid Objects Brand/1.jpg"),
                            },
                        ]
                    },
                ]
            },
            videos: [
                {
                    url: "https://player.vimeo.com/video/818100559?h=82dee89260&autoplay=1&controls=0&loop=1&h=3412c364a3&badge=0&autopause=0&player_id=0&app_id=58479",
                    dimensions: [800, 800]
                }
                
            ],
            threeParams: {
                namedViews: {
                    "HERO VIEW": {
                        "position": {
                            "x": -11.00513393712854,
                            "y": 2.9308303748684,
                            "z": -0.14346372044453737
                        },
                        "target": {
                            "x": 0,
                            "y": 2,
                            "z": 0
                        },
                        fov: { fov: 50 },
                        type: "perspective"
                    },
                },
                lights: [
                    {
                        type: "directional",
                        position: [-8, 5, 5],
                        castShadow: true,
                        target: [0, 0, 0],
                        color1: [255, 255, 255],
                        color2: [255, 241, 224],
                        intensity: 0.6,
                    },
                    {
                        type: "ambient",
                        position: [],
                        castShadow: true,
                        target: [],
                        color1: [249, 241, 234],
                        color2: [249, 241, 234],
                        intensity: 0.8,
                    }
                ],
            }
        }
    ]
}


/**
{
    "x": -11.00513393712854,
    "y": 2.9308303748684,
    "z": -0.14346372044453737
}
 */