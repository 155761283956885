/*
3D Context Manager class
*/

import * as THREE from "three";
// import { loadGLTF } from "./graphics-utils";

import playfolio from "@/assets/data/playfolio";

import store from "@/store/index.js";

class ContextManager {
    /**
     * Constructor
     * @param {*} contextParams 
     * @param contextParams.scene The threejs scene
     * @param contextParams.renderer The threejs WebGL renderer
     * @param contextParams.contextFiles The urls of the 3D files to load
     * @param {string} contextParams.currentSketch The current Tier 1 activation's name
     */
    constructor(contextParams) {
        this.currentActivation = contextParams.currentSketch;
        this.scene = contextParams.scene;
        this.renderer = contextParams.renderer;
        this.contextFiles = contextParams.contextFiles;

        this.playParams = playfolio.items.filter(item => item.title === this.currentActivation)[0].threeParams;

        this.scaling = this.playParams.scaling;
        // this.contextModels = [];

        // let instance = this;
        // for (let fileName of contextParams.contextFiles) {
        //     loadGLTF(this.onContextLoaded, fileName.default, instance);
        // }

        this.createLights();
    }

    /**
     * Assing materials to the loaded model & add it to our context
     * @param {*} contextModel The loaded GLTF model
     * @param {*} instance The current class instance
     */
    onContextLoaded(contextModel, instance) {
        let newModel = contextModel.scene;

        let contextMaterial = new THREE.MeshPhongMaterial({
            emissive: false,
            color: new THREE.Color(0.2, 0.2, 0.2),
            specular: new THREE.Color(0.2, 0.2, 0.2),
            // roughness: 0.4,
            shininess: 0.1,
            side: THREE.DoubleSide,
            transparent: false,
            opacity: 1.0
        });

        // Set the mesh to cast & receive shadows
        newModel.traverse(function (child) {
            if (child.isMesh) {
                child.castShadow = true;
                child.receiveShadow = true;
                child.geometry.computeVertexNormals(); // fix normals

                child.material = contextMaterial;
            }
        });

        // Position and scale
        newModel.position.set(0, 0, 0);
        newModel.scale.set(instance.scaling, instance.scaling, instance.scaling);

        instance.scene.add(newModel);
        instance.contextModels.push(newModel);

        // Notify store when everything is loaded
        if (instance.contextModels.length == instance.contextFiles.length) {
            store.commit("setContextLoaded", true);
            console.log("[context mgmt] context loaded")
        }
    }

    createDirLight(params) {
        var dirLight = new THREE.DirectionalLight(new THREE.Color(`rgb(${params.color1[0]}, ${params.color1[1]}, ${params.color1[2]})`), params.intensity);
        dirLight.position.set(params.position[0], params.position[1], params.position[2]);
        dirLight.castShadow = params.castShadow;

        dirLight.shadow.mapSize.width = 1024; // default
        dirLight.shadow.mapSize.height = 1024; // default
        dirLight.shadow.camera.near = 2;
        dirLight.shadow.camera.far = 50;

        const d = 10;

        dirLight.shadow.camera.left = - d;
        dirLight.shadow.camera.right = d;
        dirLight.shadow.camera.top = d;
        dirLight.shadow.camera.bottom = - d;
        dirLight.shadow.bias = -0.005;

        if (params.target.length > 0) {
            // this.scene.add(new THREE.CameraHelper(dirLight.shadow.camera));

            let targetObject = new THREE.Object3D();
            targetObject.position.set(params.target[0], params.target[1], params.target[2]);

            dirLight.target = targetObject;

            // this.scene.add(dirLight.target);
        }

        this.scene.add(dirLight);

        // const light = new THREE.DirectionalLight(0xffffff, 1);
        // light.position.set(- 10, 10, 5);
        // light.castShadow = true;
        // const d2 = 20;
        // light.shadow.camera.left = - d2;
        // light.shadow.camera.right = d2;
        // light.shadow.camera.top = d2;
        // light.shadow.camera.bottom = - d2;

        // light.shadow.camera.near = 2;
        // light.shadow.camera.far = 50;

        // light.shadow.mapSize.x = 1024;
        // light.shadow.mapSize.y = 1024;

        // this.scene.add(light);
    }

    /**
     * Read lights from the current configuration 
     * and add those to the scene
     */
    createLights() {
        console.log(this.playParams.lights)
        for (let lightParams of this.playParams.lights) {
            var color1 = `rgb(${lightParams.color1[0]}, ${lightParams.color1[1]}, ${lightParams.color1[2]})`;

            switch (lightParams.type) {
                /**
                 * Create directional light
                 * with optional target
                 */
                case "directional":
                    this.createDirLight(lightParams);
                    break;
                /**
                 * Create hemisphere light
                 */
                case "hemisphere":
                    var color2 = `rgb(${lightParams.color2[0]}, ${lightParams.color2[1]}, ${lightParams.color2[2]})`;
                    var hemLight = new THREE.HemisphereLight(color1, color2, 1);
                    this.scene.add(hemLight);
                    break;
                /**
                 * Create ambient light
                 */
                case "ambient":
                    var ambientLight = new THREE.AmbientLight(0x404040, lightParams.intensity);
                    this.scene.add(ambientLight);

                    console.log(`rgb(${lightParams.color2[0]}, ${lightParams.color2[1]}, ${lightParams.color2[2]})`)
                    break;
            }
        }
    }
}

export { ContextManager };
