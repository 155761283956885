<template>
  <div class="container-fluid sketch-container p-0 m-0">
    <div class="row m-2">
      <div class="col-12 col-sm-6 col-md-8 col-lg-9 p-0 m-0">
        <div class="graphics-container">
          <!-- Three.js container -->
          <div id="graphics" />
        </div>
      </div>

      <!-- GUI -->
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 p-0 m-0">
        <PlayGUI />
      </div>
    </div>
  </div>
</template>

<script>
/////////////
// Imports //
/////////////

import PlayGUI from "@/components/PlayGUI.vue";

import * as THREE from "three";
import Stats from "stats.js";

import { CameraManager } from "@/graphics/three-helpers/CameraManager.js";
import { ContextManager } from "@/graphics/three-helpers/ContextManager.js";
import { SoftSerifManager } from "@/graphics/Soft Serif/SoftSerifManager.js";

/////////////
// Options //
/////////////

export default {
  components: {
    PlayGUI,
  },
  props: ["currentSketch"],
  data() {
    /**
     * Threejs Workaround in Vue 3
     * https://stackoverflow.com/questions/65693108/threejs-component-working-in-vuejs-2-but-not-3
     *
     * Declaring Three variables as non-reactive component properties
     */

    /** The Threejs camera */
    this.camera = null;

    /** The Threejs simulation scene */
    this.scene = null;

    /** The Threejs renderer */
    this.renderer = null;

    /** Performance statistics */
    this.stats = null;

    /** The camera manager */
    this.cameraManager = null;

    /** The context manager */
    this.contextManager = null;

    /** Ther current activation's animation manager */
    this.animationManager = null;

    // Reactive properties
    return {
      currentView: "PERSPECTIVE",
      useContext: false,
      width: 0,
      height: 0,
      isVisible: true,
      renderComponent: true,
    };
  },
  computed: {
    isDevEnv() {
      return this.$store.state.isDevEnv;
    },
    showStats() {
      return this.$store.state.guiParams.showFramerate;
    },
    backgroundColor() {
      return this.$store.state.guiParams.sceneSettings
        ? this.$store.state.guiParams.sceneSettings.backgroundColor
        : [0, 0, 255];
    },
    fog() {
      return this.$store.state.guiParams.sceneSettings.fog;
    },
    currentCamera() {
      return this.$store.state.guiParams.cameraSettings.currentCamera;
    },
    currentNamedView() {
      return this.$store.state.guiParams.cameraSettings.currentNamedView;
    },
    gravity() {
      return this.$store.state.guiParams.sketchSettings.gravity;
    },
  },
  watch: {
    /**
     * Show performance statistics / framerate
     */
    showStats: function (val) {
      let stats = document.getElementById("stats");
      if (val) {
        if (!stats) document.body.appendChild(this.stats.dom);
      } else {
        if (stats) {
          document.body.removeChild(this.stats.dom);
        }
      }
    },

    /**
     * Update simulation backgrond color
     */
    backgroundColor: function (backgroundColor) {
      this.updateBackground(backgroundColor);
    },

    isVisible: function (val) {
      if (!val) {
        this.renderer.domElement.style.display = "none";
      } else {
        this.renderer.domElement.style.display = "flex";
      }
    },

    currentCamera: function (val) {
      this.cameraManager.setActiveCamera(val);
    },
    currentNamedView: function (val) {
      if (val == "") return;

      this.cameraManager.setNamedView(val);
    },
    gravity: function (val) {
      console.log(val);
      this.animationManager.updateGravity(val);
    },
  },
  mounted: function () {
    console.log("[simulation] mounted");

    window.addEventListener("resize", this.onWindowResize);

    // Load sketch
    this.initSketch();

    // Hide until context is loaded
    // this.renderer.domElement.style.display = "none";
  },
  methods: {
    async forceRerender() {
      // Remove MyComponent from the DOM
      this.renderComponent = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;
    },
    onClick: function () {},
    initStats: function () {
      this.stats = new Stats();
      this.stats.dom.id = "stats";
    },
    /**
     * Initialize the threejs scene
     */
    initSketch: async function () {
      // Create scene objects
      this.initSimulation();

      // Create camera manager
      let cameraParams = {
        currentSketch: String(this.currentSketch),
        scene: this.scene,
        renderer: this.renderer,
        width: this.width,
        height: this.height,
      };
      this.cameraManager = new CameraManager(cameraParams);

      // Create animation
      await this.initAnimation();

      // Create context
      let contextFileNames = [];
      this.createContext(contextFileNames);

      // Add stats
      this.initStats();

      // Begin animation
      this.animate();
    },
    /**
     * Create the 3D context for the current T1 activation
     */
    createContext: function (contextFileNames) {
      let contextParams = {
        scene: this.scene,
        renderer: this.renderer,
        contextFiles: contextFileNames,
        currentSketch: String(this.currentSketch),
      };
      this.contextManager = new ContextManager(contextParams);
    },
    /**
     * Create scene & renderer
     */
    initSimulation: function () {
      this.initRenderer();
      this.initScene();
    },

    /**
     * Set up the current activation's animation manager
     * based on the name of the current route
     */
    initAnimation() {
      // Get settings from config file
      let currentParams = {};
      currentParams.scene = this.scene;
      currentParams.renderer = this.renderer;
      currentParams.cameraManager = this.cameraManager;

      switch (this.currentSketch) {
        case "Soft Serif":
          // perspective bloom pass for managing selection
          currentParams.bloomPass = this.cameraManager.perspectiveBloomPass;

          this.animationManager = new SoftSerifManager(currentParams);
          break;
      }
    },

    /**
     * Initialize the threejs scene
     */
    initScene() {
      // Create simulation scene
      this.scene = new THREE.Scene();
      this.updateBackground(this.backgroundColor);
    },

    /**
     * Initialize the Threejs WebGL renderer
     */
    initRenderer: function () {
      let container = document.getElementById("graphics");

      // Create renderer & append it to the dom
      this.renderer = new THREE.WebGLRenderer({
        preserveDrawingBuffer: false,
        antialias: true,
        // powerPreference: "high-performance",
        stencil: false,
        depth: true,
      });

      // this.renderer.outputEncoding = THREE.sRGBEncoding;
      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      // this.renderer.shadowMap.type = THREE.PCFShadowMap ;

      // this.renderer.toneMapping = THREE.CineonToneMapping;
      // this.renderer.toneMappingExposure = 1.0;

      this.width = container.offsetWidth;
      this.height = container.offsetWidth;
      this.renderer.setSize(this.width, this.height);

      container.appendChild(this.renderer.domElement);
    },

    /**
     * Animate
     */
    animate: function () {
      requestAnimationFrame(this.animate);

      if (!this.stats) return;
      if (!this.animationManager.isAmmoLoaded()) return;

      this.stats.begin();
      this.animationManager.render();

      switch (this.currentCamera) {
        case "ORTHO":
          this.cameraManager.renderOrtho();
          break;
        case "PERSPECTIVE":
          this.cameraManager.updateControls();
          this.cameraManager.renderPerspective();
          break;
      }

      // this.animationManager.renderVideo();

      this.stats.end();
    },

    onWindowResize() {
      let container = document.getElementById("graphics");
      // this.width = container.clientWidth;
      // this.height = container.clientHeight;
      console.log(container);
      this.width = container.clientWidth;
      this.height = container.clientWidth;
      this.renderer.setSize(this.width, this.height);
      // this.renderer.setSize(window.innerWidth, window.innerHeight);

      this.cameraManager.onWindowResize(this.width, this.height);
    },

    updateBackground(backgroundColor) {
      this.scene.background = new THREE.Color(backgroundColor);

      // Add fog
      const color = new THREE.Color(backgroundColor);
      const density = this.fog;
      this.scene.fog = new THREE.FogExp2(color, density);
    },
  },
};
</script>

<style scoped>
#graphics {
  pointer-events: all;
  /* z-index: 500000; */

  /* width: 80vw;
  height: 70vh; */
  max-width: 100%;
  max-height: 100%;
}

.graphics-container {
  height: 100%;
  width: 100%;

  overflow-y: scroll !important;

  display: block;

  border-radius: 10px;
}

.sketch-container {
  overflow-y: scroll !important;
}
</style>

