<template>
<div class="outer-container">
  <div class="play-popup">
    <PlayLightboxHeader :projectInfo="projectInfo" />
    <ThreeContainer :currentSketch="projectInfo.title"/>
  </div>
</div>
</template>

<script>
import ThreeContainer from '../graphics/three-helpers/ThreeContainer.vue';
import PlayLightboxHeader from '@/components/PlayLightboxHeader.vue';

export default {
  name: "Play Code Sandbox",
  props: ["projectInfo", "showDetails"],
  components: {
    ThreeContainer,
    PlayLightboxHeader
  },
  data: function () {
    return {
    };
  },
  methods: {
    cssProps: function () {
      return {
        "--pointerCursor": "url(" + window.pointerCursor + ") 6 6, pointer",
      };
    },
    
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./../assets/scss/_variables.scss";

// https://stackoverflow.com/questions/60162891/clip-scrollbar-inside-rounded-div-corners-css

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(38, 36, 36, 0.3);
  background-color: transparent;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 6px #f8f5f1;
  background-color: #d6d1cb;
  height: 1rem;
}

.lightbox-modal {
  cursor: var(--closeCursor);
}

.lightbox-container {
  cursor: var(--closeCursor);
}

.btn-hovered {
  background-color: $button-black !important;
  color: white !important;
  cursor: var(--pointerCursor);
}

.sticky-row {
  position: fixed !important;
  top: 0;
  right: 0;
  margin-right: 0;
}

.play-popup {
  top: 0;
  left: 0;
  
  overflow-x: hidden;
  // overflow-y: auto;
  // overflow: auto;
}

.stick-button {
  position: sticky;
}

.outer-container {
  top: 10vh;
  left: 5vw;
  height: 85vh;
  width: 90vw;

  z-index: 50000 !important;
  position: fixed;

  background: #f8f5f1;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 15px;

  overflow-y: scroll;
  overflow-x: hidden;
  // overflow-y: scroll;
  border-radius: 10px;

  pointer-events: all !important;

  // padding: 15px;

}


</style>