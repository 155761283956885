<template>
  <div class="playground-container" :style="cssProps">
    <Navbar msg="Playground" />
    <PlayBackground />
    <div class="container-fluid hello-text p-2 m-4">
      <div class="row">
        <div class="col-md-5 col-sm-11 col-11">
          <h2>
            Welcome to my playground! These are some &#10042; experiments
            &#10042; sketches &#10042; studies &#10042; I'm working through.
            Most recently using Houdini, three.js, GLSL, Figma, and more.
          </h2>
        </div>
      </div>
    </div>

    <!-- FOR PHONE -->
    <!-- <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3" v-for="(project, index) in filteredProjects" :key="project">
            <PlayItem :projectData="project" :index="index+1" />
          </div>
        </div>
      </div> -->
    <PlayItem
      :projectData="project"
      :index="index + 1"
      v-for="(project, index) in filteredProjects"
      :key="project"
    />
    <SideBar title="About" offset="0" z="5000" sidebarOpen="false" />
    <div
      class="tooltip-div"
      v-if="windowWidth > 768"
      :style="{
        pointerEvents: 'all',
        position: 'absolute',
        left: mouseX + 25 + 'px',
        top: mouseY + 25 + 'px',
        zIndex: 5000,
        maxWidth: '20vw',
      }"
    >
      <h4>{{ mouseX }}, {{ mouseY }}</h4>
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import PlayItem from "@/components/PlayItem.vue";
import SideBar from "@/components/SideBar.vue";
// import Footer from "@/components/Footer.vue";
import PlayBackground from "@/components/PlayBackground.vue";

export default {
  name: "Playground",
  components: {
    Navbar,
    PlayItem,
    SideBar,
    // Footer,
    PlayBackground,
  },
  data: function () {
    return {
      filteredProjects: null,
      userFilter: "",
      mouseX: -1,
      mouseY: -1,
      windowWidth: window.innerWidth,
      navbarHeight:
        window.innerWidth > 768
          ? window.navbarDims[1] + "px"
          : window.navbarDims[0] + "px",
    };
  },
  computed: {
    projects() {
      return this.$store.state.experiments;
    },
    cssProps: function () {
      return {
        "--pointerCursor": "url(" + window.pointerCursor + ") 6 6, pointer",
        "--arrowCursor": "url(" + window.arrowCursor + ") 6 6, auto",
        "--navbarHeight": this.navbarHeight,
      };
    },
  },
  watch: {},
  methods: {
    updateFilter() {
      let curFilter = this.userFilter;
      console.log(curFilter);
      if (curFilter == "") {
        console.log("empty filter");
        this.filteredProjects = this.projects;
        return;
      }

      this.filteredProjects = this.projects.filter(function (obj) {
        let tagList = obj["Tags"].toLowerCase().trim().split(",");
        tagList = tagList.map((x) => x.trim());
        console.log(tagList);
        return tagList.includes(curFilter);
      });
    },
    onMouseMove: function (event) {
      this.mouseX = event.pageX;
      this.mouseY = event.pageY;
    },
    onResize() {
      this.windowWidth = window.innerWidth;

      this.navbarHeight =
        window.innerWidth > 768
          ? window.navbarDims[1] + "px"
          : window.navbarDims[0] + "px";
    },
  },
  mounted() {
    document.addEventListener("mousemove", this.onMouseMove);
    window.addEventListener("resize", this.onResize);

    this.filteredProjects = this.projects;
  },
};
</script>

<style scoped lang="scss">
.playground-container {
  cursor: var(--arrowCursor);
  // scroll-margin-top: 80px;

  background-color: #ece9e2;

  width: 100vw;
  height: 150vh;

  overflow: hidden;
  overflow-x: hidden;

  z-index: 100;
}

.circle {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 1.25rem;
  font-size: 0.75rem;
  color: #fff;
  line-height: 1.4rem;
  text-align: top;
  background: #000;
  cursor: var(--pointerCursor);
}

.hello-text {
  position: absolute;

  top: var(--navbarHeight);
  left: 0px;
}

.hello-text h2 {
  color: grey;
}
</style>