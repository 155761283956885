import {createStore} from "vuex";

const updateByKey = (obj, kee, newVal) => {
    if (kee in obj) {
        obj[kee] = newVal;
        return obj[kee];
    }

    for(let n of Object.values(obj).filter(Boolean).filter(v => typeof v === 'object')) {
        let found = updateByKey(n, kee, newVal)
        if (found) return found;
    }
}

export default createStore({
    state: {
        /**
         * Local images for each featured project
         */
        projectImages: null,

        projects: {},

        /**
         * Projects for the UX portfolio page
         */
        products: {},
        productImages: null,

        /**
         * Experiments data
         */
        experiments: {},

        experimentsImages: null,

        /**
         * GUI parameters for code sketches
         */
        guiParams: {
            showFramerate: false,

            /** Scene settings */
            sceneSettings: {
                // backgroundColor: 'rgba(223, 0, 223, 1)',
                backgroundColor: "#D8B7A6",
                fog: 0.001,
            },

            /** Camera and view settings */
            cameraSettings: {
                currentCamera: "PERSPECTIVE",
                cameras: ["PERSPECTIVE"],
            },

            sketchSettings: {
                gravity: -0.8,
            }

        },

        topIndex: 1000,

        hidePopup: false,

    },
    mutations: {
        setProjectImages(state, images) {
            state.projectImages = images;
        },
        setProjects(state, payload) {
            state.projects = payload; 
        },
        setProducts(state, payload) {
            state.products = payload; 
        },
        setProductImages(state, payload) {
            state.productImages = payload; 
        },
        setExperimentsImages(state, images) {
            state.experimentsImages = images;
        },
        setExperiments(state, payload) {
            state.experiments = payload; 
        },
        updateGuiParam(state, param) {
            updateByKey(state.guiParams, param.key, param.val);
        },
        incrementTopIndex(state) {
            state.topIndex += 1;
        },
        togglePopup(state) {
            state.hidePopup = !state.hidePopup;
        }
    },
    actions: {
    },
    getters: {
    },
});


