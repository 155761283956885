<template>
  <!-- LIGHTBOX IMAGES -->
  <div v-if="isVisible" :style="cssProps" class="lightbox-container">
    <div
      class="row m-0 p-0 align-items-end"
      :style="{
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        zIndex: 5000,
        pointerEvents: 'auto !important',
        position: 'fixed',
      }"
    >
      <div 
      class="
            row container-fluid
            align-self-bottom
            justify-content-center
            lightbox
            m-0
            p-0
          "
          :style="{
            zIndex: 5000,
            height: lightboxHeight,
            width: '100%',
          }"
      >
      <div class="row mt-4">
          <div class="col-md-2 offset-md-10 col-lg-1 offset-lg-11">
          <button
            type="button"
            class="btn ps-2"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
          HIDE &#8601;
        </button>
          </div>
      </div>
      <div class="row" style="max-height: 100%">
        <div class="col-12" style="max-height: 100%">
          <img
            class="img-fluid"
            :src="this.imageSrc"
            :style="{
              height: 'auto !important',
              width: 'auto !important',
              maxWidth: '90% !important',
              maxHeight: '90% !important',
              margin: 'auto',
              display: 'block'
            }"
          />
      </div>
      </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "LightboxImage",
  props: ["imageSrc", "isVisible"],
  components: {},
  data: function () {
    return {
      videoSize: [1920, 1200],
      scale: [1, 1],
      videoRescaledSize: [1536, 864],
      lightboxHeight: (window.innerWidth>768)?window.innerHeight-window.navbarDims[1] + 'px':window.innerHeight-window.navbarDims[0] + 'px',
    };
  },
  methods: {
    setScaling() {
      let vidSizeRatio = this.videoSize[0] / this.videoSize[1];
      let screenRatio = window.innerWidth / window.innerHeight;
      let modHeight =
        this.videoSize[1] * (window.innerWidth / this.videoSize[0]);
      let modWidth =
        this.videoSize[0] * (window.innerHeight / this.videoSize[1]);
      let height = screenRatio > vidSizeRatio ? modHeight : window.innerHeight;
      let width = screenRatio > vidSizeRatio ? window.innerWidth : modWidth;
      this.scale = [width / this.videoSize[0], height / this.videoSize[1]];
      this.videoRescaledSize = [width, height];
      
      this.lightboxHeight = (window.innerWidth>768)?window.innerHeight-window.navbarDims[1] + 'px':window.innerHeight-window.navbarDims[0] + 'px'
    },
    hideLightbox() {
      console.log(this.videoUrl);
      this.$root.isLightboxVisible = false;
    },
    cssProps: function () {
      return {
        "--pointerCursor": "url(" + window.pointerCursor + ") 6 6, pointer",
        "--closeCursor": "url(" + window.closeCursor + ") 6 6, auto !important",
        "--cameraCursor": "url(" + window.cameraCursor + ") 6 6, pointer",
      };
    },
  },
  created() {
    this.setScaling();
    window.addEventListener("resize", this.setScaling);
  },
  unmounted() {
    window.removeEventListener("resize", this.setScaling);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "./../assets/scss/_variables.scss";

.lightbox {
  border-top: 1px solid black !important;
  border-bottom: 1px solid black !important;
  background: white !important;
}

.lightbox-container {
  cursor: var(--closeCursor);
}
</style>