<template>
  <div class="container-fluid p-4 pb-0 pt-2" :style="cssProps">
    <div class="row g-0">
      <div class="col-12">
        <div class="portfolio-item p-1"></div>
      </div>
    </div>
    <a :href="hashedId"></a>
    <!-- <div class="row w-100 pe-2 position-absolute" style="z-index:5000;">
        <div class="row w-100" v-if="isOpen && isHovered" >
          <div
            class="
              d-flex
              col-sm-2 offset-sm-10
              col-md-2
              offset-md-10
              col-lg-1
              offset-lg-11
              justify-self-end
              align-top
              flex-row-reverse
              p-0
            "
            style="position: sticky;"
          >
            <button type="button" class="btn float-right mt-0 me-0">
              LESS &#129124;
            </button>
          </div>
        </div>
      </div> -->

    <div
      class="row portfolio-row"
      :id="index"
      @mouseover="isHovered = true"
      @mouseleave="isHovered = false"
      @click="toggleOpen()"
      :style="{
        maxHeight: String(curHeight) + 'px',
      }"
    >
      <div class="row w-100 position-absolute pe-4" v-if="isOpen">
        <div
          class="
            d-flex
            col-sm-2
            offset-sm-10
            col-md-2
            offset-md-10
            col-lg-1
            offset-lg-11
            justify-self-end
            align-top
            flex-row-reverse
            p-0
          "
          style="position: sticky"
        >
          <button type="button" class="btn float-right mt-0 me-0">
            LESS &#8598;
          </button>
        </div>
      </div>

      <div class="row thumbnail-row pe-0">
        <div class="d-flex col-md-4 col-sm-7 mb-2 d-lg-none">
          <img
            v-if="thumbnails[0]"
            class="img-fluid d-lg-none"
            :style="{
              maxWidth: '100% !important',
              maxHeight: '100% !important',
              width: '100% !important',
              height: 'auto !important',
              display: 'block',
            }"
            :src="thumbnails[0].src"
          />
        </div>
        <div class="col-md-6 col-sm-5 col-lg-2 justify-content-start">
          <div class="row">
            <div class="row mb-2">
              <div class="col-sm-1 col-md-8 col-lg-8 me-1 mb-1">
                <div class="circle">{{ this.index }}</div>
              </div>
              <div class="col-sm-8 col-md-8 col-lg-8">
                <h3 class="ms-0 mt-1 item-title">{{ title }}</h3>
              </div>
            </div>
            <div class="row d-lg-none">
              <div class="d-flex col-sm-12">
                <h4 class="text-left">{{ projectId }}</h4>
              </div>
            </div>
            <div class="row d-lg-none mt-1">
              <div class="d-flex col-sm-12">
                <h3 class="text-left">{{ abstract }}</h3>
              </div>
            </div>
            <div class="row d-lg-none mt-1 d-none d-sm-block mt-2">
              <div class="col-12">
                <div v-for="tag in tags.split(',')" :key="tag">
                  <h5 class="text-left tag">&#8594; {{ tag.trim() }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex col-md-1 justify-content-start mt-1 d-none d-lg-block"
        >
          <h4>{{ projectId }}</h4>
        </div>
        <div
          class="
            d-flex
            col-md-1
            justify-content-start
            text-left
            mt-1
            d-none d-lg-block
          "
        >
          <div class="row">
            <h4>{{ category }}</h4>
          </div>
        </div>
        <div
          class="
            d-flex
            col-md-4 col-lg-2
            justify-content-start
            mt-1
            d-none d-lg-block
          "
        >
          <div class="row">
            <div class="col-12">
              <h4>
                {{ abstract }}
              </h4>
            </div>
            <div class="col-12 mt-2">
              <div v-for="tag in tags.split(',')" :key="tag">
                <h5 class="text-left tag">&#8594; {{ tag.trim() }}</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex col-lg-5 d-none d-lg-block">
          <div class="row p-0 m-0">
            <div
              class="col-md-4 p-1 pt-0 pb-0"
              v-for="image in thumbnails.slice(0, 3)"
              :key="image"
              :style="{}"
            >
              <img
                class="img-fluid d-none d-lg-block"
                :style="{
                  maxWidth: '100% !important',
                  width: '100% !important',
                  height: 'auto !important',
                  maxHeight: '100% !important',
                }"
                :src="image.src"
              />
            </div>
          </div>
        </div>

        <div
          class="
            d-flex
            col-md-2 col-lg-1
            justify-content-end
            align-top
            flex-row-reverse
            p-0
            d-none d-md-block
          "
          v-if="!isHovered && !isOpen"
        >
          <button type="button" class="btn float-right mt-0 me-0">
            Info &#8595;
          </button>
        </div>
        <div
          class="
            d-flex
            col-md-2 col-lg-1
            justify-content-end
            align-top
            flex-row-reverse
            p-0
            d-none d-md-block
          "
          v-else-if="!isOpen"
        >
          <button type="button" class="btn btn-hovered float-right mt-0 me-0">
            Info &#8595;
          </button>
        </div>
        <!-- <div
          class="
            d-flex
            col-md-2 col-lg-1
            justify-content-end
            align-top
            flex-row-reverse
            p-0
            d-none d-md-block
          "
          v-else
        >
          <button type="button" class="btn btn-hovered float-right mt-0 me-0">
            Less &#129124;
          </button>
        </div> -->
      </div>

      <div v-if="showDetails">
        <ItemDetails
          :projectInfo="projectInfo"
          :showDetails="showDetails"
          :images="images"
        />
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import ItemDetails from "@/components/ItemDetails.vue";

export default {
  name: "Portfolio Item",
  components: {
    ItemDetails,
  },
  props: ["projectData", "index"],
  data: function () {
    return {
      title: this.projectData["Project Title"],
      projectId: this.projectData["ID"],
      hashedId: "#" + String(this.index),
      category: this.projectData["Category"],
      abstract: this.projectData["Abstract"],
      imageFolder: this.projectData["Project Title"],
      tags: this.projectData["Tags"],
      projectInfo: this.projectData["Project Info"],
      isHovered: false,
      maxHeight: window.innerWidth > 768 ? 240 : 480,
      closedHeight: "auto",
      openHeight:
        window.innerWidth > 768
          ? window.innerHeight * 0.7
          : window.innerHeight * 0.8,
      curHeight: window.innerWidth > 768 ? 360 : 800,
      maxImageHeight: window.innerWidth > 768 ? 240 : 400,
      isOpen: false,
      showDetails: false,
      overflow: "hidden",
      buttonText: "Info",
      mouse: [-1, -1],
      isTweening: false,
    };
  },
  methods: {
    onResize() {
      if (this.isOpen) this.toggleOpen();
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 768) {
        this.curHeight = 700;
        this.closedHeight = "auto";
        this.maxImageHeight = 360;
        this.openHeight = window.innerHeight * 0.8;
      } else {
        this.curHeight = 360;
        this.closedHeight = "auto";
        this.maxImageHeight = 240;
        this.openHeight = window.innerHeight * 0.7;
      }

      // let element = document.getElementById(this.index);
      // const rect = element.getBoundingClientRect();
      // if (this.isOpen) {
      //   // this.maxHeight = rect.height;
      //   this.curHeight = rect.height;
      // }
      // else {
      //   this.curHeight = rect.height;
      //   this.closedHeight = rect.height;
      // }

      // console.log(this.curHeight);
    },
    toggleOpen() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) this.expandItem();
      else this.shrinkItem();
    },
    onMouseMove: function (event) {
      this.mouse[0] = event.pageX;
      this.mouse[1] = event.pageY;
    },
    expandItem() {
      let element = document.getElementById(this.index);
      const rect = element.getBoundingClientRect(); // get rects(width, height, top, etc)
      // let viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0, document.documentElement.scrollHeight);

      let navbarHeight =
        window.innerWidth > 768 ? window.navbarDims[1] : window.navbarDims[0];
      let rectTopScroll = rect.top + window.scrollY - (navbarHeight + 10);

      // console.log("[rect top] " + rect.top);
      // console.log("[navbar height] " +navbarHeight);
      // console.log("[window height] " + window.innerHeight);
      // console.log("[rect top + scroll]" + rectTopScroll);
      // console.log("[window scroll y] " + window.scrollY);
      // console.log("[scroll to] " + rectTopScroll);

      // let topPos = rectTopScroll + rect.height*0.5 - viewHeight*0.5;

      window.scrollTo({
        top: rectTopScroll,
        behavior: "smooth", // smooth scroll
      });

      this.closedHeight = rect.height;
      this.maxHeight = this.openHeight;
      this.overflow = "scroll";
    },
    shrinkItem() {
      this.maxHeight = this.closedHeight;
      this.overflow = "hidden";
      var curDiv = document.getElementById(this.index);
      curDiv.scrollTop = 0;
    },
  },
  computed: {
    images() {
      let imgStore = this.$store.state.projectImages;
      return imgStore[this.projectData["Project Title"]].images;
    },
    thumbnails() {
      let imgStore = this.$store.state.projectImages;
      return imgStore[this.projectData["Project Title"]].thumbnails;
    },
    cssProps: function () {
      return {
        "--pointerCursor": "url(" + window.pointerCursor + ") 6 6, pointer",
        "--cameraCursor": "url(" + window.cameraCursor + ") 6 6, pointer",
        "--closeCursor": "url(" + window.closeCursor + ") 6 6, pointer",
        "--overflow": this.overflow,
        "--maxHeight": this.closedHeight + "px",
      };
    },
  },
  watch: {
    maxHeight(newValue) {
      let isExpanding = true;

      if (!this.isOpen) isExpanding = false;
      if (isExpanding) {
        this.showDetails = true;
        // console.log("[expanding]");
      } else {
        // console.log("[shrinking]");
      }
      gsap.to(this.$data, {
        duration: 0.5,
        curHeight: newValue,
        delay: 0.0,
        onComplete: () => {
          if (!isExpanding) {
            this.showDetails = false;
          }
          this.isTweening = false;
        },
        onStart: () => {
          this.isTweening = true;
        },
      });
    },
  },
  mounted() {
    document.addEventListener("mousemove", this.onMouseMove);
    window.addEventListener("resize", this.onResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./../assets/scss/_variables.scss";

h4 {
  text-align: left;
}

.item-title {
  line-height: 1.2rem;
  text-transform: uppercase;

  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.circle {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 1.25rem;
  font-size: 0.75rem;
  color: #fff;
  line-height: 1.25rem;
  text-align: top;
  background: #000;
}

.portfolio-row:hover {
  cursor: var(--pointerCursor);
}

img:hover {
  cursor: var(--cameraCursor);
}

.portfolio-row {
  overflow: var(--overflow);
}

.thumbnail-row {
  height: var(--maxHeight);
  max-height: var(--maxHeight);
}

.btn-hovered {
  background-color: $button-black !important;
  color: white !important;
  cursor: var(--pointerCursor);
  position: sticky !important;
  top: 0;
}

.btn:hover {
  cursor: var(--pointerCursor);
}

.tag {
  text-transform: uppercase;
}
</style>
