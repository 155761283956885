<template>
  <div
    class="play-container p-1 pb-2 pt-2 m-0"
    :style="cssProps"
    :id="`drag-${index}`"
  >
    <a :href="hashedId"></a>
    <div
      class="col-12 col-12 p-0 playitem-container"
      :id="index"
      @mouseover="isHovered = true"
      @mouseleave="isHovered = false"
    >
      <div class="row drag-row" >
        <!-- <img
          v-if="thumbnail"
          class="img-fluid thumbnail-img play-thumbnail"
          :src="thumbnail.src"
        /> -->
        <PlayVideo v-if="String(thumbnail.url).includes('vimeo')" :video="thumbnail" :isThumbnail="true"/>
        <PlayImage v-else :url="thumbnail.src" :style="imgCssProps"/>
      </div>
      <div class="row p-3">
        <div class="col-12">
        <h4 class="ms-0 mt-1 item-title">{{ title }}</h4>
        <h5 class="text-left text-uppercase">{{ projectData.year }} &#65121; {{ projectInfo.type }} </h5>
        <div class="mt-3 justify-content-start d-flex">
          <button
            type="button"
            class="btn float-right mt-0 me-0 play-btn"
            @click="toggleOpen()"
            @touchstart="toggleOpen()"
            @mouseover="onMouseOver()"
          >
            {{infoButtonText}} &#8594;
          </button>
        </div>
      </div>
      </div>
    </div>
  </div>

  <PlayDetailsLightbox
    v-if="showDetails && !isCode"
    :projectInfo="projectInfo"
    :showDetails="showDetails"
    :media="media"
  />
  <PlayCodeLightbox
    v-if="showDetails && isCode"
    :projectInfo="projectInfo"
    :showDetails="showDetails"
  />
</template>

<script>
import PlayCodeLightbox from "@/components/PlayCodeLightbox.vue";
import PlayDetailsLightbox from "@/components/PlayDetailsLightbox.vue";
import Draggable from "plain-draggable";
import PlayImage from './PlayImage.vue';
import PlayVideo from './PlayVideo.vue';

export default {
  name: "Play Item",
  components: {
    PlayCodeLightbox,
    PlayDetailsLightbox,
    PlayVideo,
    PlayImage
  },
  props: ["projectData", "index"],
  data: function () {
    return {
      title: this.projectData.title,
      projectId: this.projectData.id,
      hashedId: "#" + String(this.index),
      category: this.projectData.category,
      abstract: this.projectData.abstract,
      imageFolder: this.projectData.title,

      projectInfo: this.projectData.info,

      isHovered: false,
      curHeight: window.innerWidth > 768 ? 360 : 800,
      maxImageHeight: window.innerWidth > 768 ? 240 : 400,
      isOpen: false,
      showDetails: false,
      overflow: "hidden",
      buttonText: "Info",
      mouse: [-1, -1],
      isTweening: false,
      draggable: undefined,

      zIndex: 0,

      infoButtonText: "Open Sketch "
    };
  },
  methods: {
    onMouseOver() {
    },
    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
    toggleOpen() {
      this.showDetails = !this.showDetails;

      console.log("toggle open")
    },
    hideDetails() {
      this.showDetails = false;
    },
    onMouseMove: function (event) {
      this.mouse[0] = event.pageX;
      this.mouse[1] = event.pageY;
    },
  },
  computed: {
    hidePopup() {
      return this.$store.state.hidePopup;
    },
    media() {
      let mergedMedia = [...this.images, ...this.projectData.videos];
      let shuffMedia = mergedMedia.sort(() => Math.random() - 0.5);
      console.log(shuffMedia);
      return shuffMedia;
    },
    images() {
      let imgStore = this.$store.state.experimentsImages;
      return imgStore[this.projectData.title].images;
    },
    thumbnail() {
      let imgStore = this.$store.state.experimentsImages;
      return this.projectData.videos[0] || imgStore[this.projectData.title].images[0];
      // return imgStore[this.projectData.title].images[0];
    },
    imgCssProps: function () {
      return {};
    },
    isCode() {
      return this.projectData.isCode;
    },
    width() {
      return Math.random() * 0.7 * window.innerWidth;
    },
    cssProps: function () {
      return {
        "--pointerCursor": "url(" + window.pointerCursor + ") 6 6, pointer",
        "--cameraCursor": "url(" + window.cameraCursor + ") 6 6, pointer",
        "--closeCursor": "url(" + window.closeCursor + ") 6 6, pointer",
        "--dragCursor": "url(" + window.dragCursor + ") 6 6, pointer",
        "--overflow": this.overflow,
        "--xPos": Math.random() * window.innerWidth * 0.6 + "px",
        "--yPos": Math.random() * window.innerHeight * 0.6 + window.innerHeight * 0.1 + "px",
        "--width": "fit-content",
      };
    },
  },
  watch: {
    hidePopup() {
      if (this.showDetails) this.hideDetails();
    }
  },
  mounted() {
    document.addEventListener("mousemove", this.onMouseMove);
    window.addEventListener("resize", this.onResize);

    let dragEl = document.getElementById(`drag-${this.index}`);
    dragEl.style["z-index"]  = this.zIndex;

    this.draggable = new Draggable(
      document.querySelector(`#drag-${this.index}`)
    );

    this.draggable.distance = 0;
    this.draggable.zIndex = false;

    this.draggable.draggableClass = "plain-draggable";
    // this.draggable.target = ".playground-container";

    this.draggable.onDragStart = () => {
      this.$store.commit("incrementTopIndex");

      let dragEl = document.getElementById(`drag-${this.index}`);
      dragEl.style["z-index"]  = this.$store.state.topIndex;
    }
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./../assets/scss/_variables.scss";

h4 {
  text-align: left;
}

.item-title {
  line-height: 1.2rem;
  text-transform: uppercase;

  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.play-container {
  // width: 20vw;
  // width: var(--width);
  width: auto;
  height: auto;
  min-width: 10vw;
  max-width: 80vw;

  min-height: 10vh;
  max-height: 40vh;

  // width: fit-content;
  // align-self: flex-start;

  position: absolute;

  top: var(--yPos);
  left: var(--xPos);

  // z-index: 1000;
}

.playitem-container {
  align-items: flex-start;
  padding: 0px 0px 15px;
  gap: 10px;

  // background: #FFFFFF;
  // // border: 1px solid #000000;
  // border-radius: 15px;
  // box-shadow: 0 0 0 1px black;

  background: #f8f5f1;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 15px;

  box-sizing: border-box;

  overflow: hidden;

  // z-index: -1;
}

.plain-draggable {
  cursor: var(--dragCursor) !important;
}

.play-btn {
  pointer-events: all !important;
}

.circle {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 1.25rem;
  font-size: 0.75rem;
  color: #fff;
  line-height: 1.4rem;
  text-align: top;
  background: #000;
  margin: 0;
  padding: 0;
}

.portfolio-row:hover {
  cursor: var(--pointerCursor);
}

// img:hover {
//   cursor: var(--cameraCursor);
// }

.thumbnail-img {
  // box-shadow: 0 0 0 1px black;
  border-radius: 15px;

  cursor: var(--dragCursor) !important;
}

.portfolio-row {
  overflow: var(--overflow);
}

.btn-hovered {
  background-color: $button-black !important;
  color: white !important;
  cursor: var(--pointerCursor);
  position: sticky !important;
  top: 0;
}

.btn:hover {
  cursor: var(--pointerCursor);
}

.drag-row {
  pointer-events: none;

  max-width: 100%;
}

.tag {
  text-transform: uppercase;
}
</style>
