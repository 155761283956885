<template>
    <img
        class="img-fluid p-0 w-100"
        style="width: 100%; max-width: 100%; height: auto !important"
        :src="url"
        />
</template>

<script>
export default {
  name: "PlayImage",
  props: ["url"],
  components: {},
  data: function () {
    return {};
  },
};
</script>

<style scoped>
</style>