<template>
  <div class="item-detail">
    <div class="row detail-row mt-2 pe-0">
      <div class="col-lg-6 col-xl-4 col-md-8 col-sm-10 justify-content-start">
        <div class="row">
          <div class="col-12">
            <h2
              class="mt-1 mb-4"
              v-html="this.projectInfo.shortDescription"
            ></h2>
          </div>
          <div class="col-12 mb-2">
            <h3 v-html="this.projectInfo.longDescription"></h3>
          </div>
          <div class="col-12 mb-4">
            <h3 v-html="this.projectInfo.roleDescription"></h3>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-12 col-xl-6 offset-xl-1 mt-1">
        <div
          class="p-1 pt-0 pb-0 pe-2 ps-0 mt-2"
          v-for="image in images.slice(0, 1)"
          :key="image"
        >
          <img
            class="img-fluid"
            style="max-width: 100%; height: auto !important"
            :src="image.src"
            @click.stop="onImageClicked(image)"
          />
        </div>
      </div>
    </div>
    <div class="row mt-4" v-for="section in sections" :key="section.title">
      <div class="col-lg-6 col-xl-4 col-md-8 col-sm-10 justify-content-start">
        <div class="row d-md-none">
          <div class="col-12 mb-2">
            <h4 class="text-left" style="text-transform: uppercase">
              {{ section.title }}
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-2">
            <h3 v-html="section.description"></h3>
          </div>
        </div>
      </div>
      <div
        class="col-md-2 col-lg-1 col-xl-1 justify-content-start text-left mb-2"
      >
        <div class="row d-none d-md-block">
          <div class="col-12 mt-1">
            <h4 class="text-left" style="text-transform: uppercase">
              {{ section.title }}
            </h4>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-12 col-xl-6 mt-1">
        <div
          class="pt-0 pb-0 mb-4"
          v-for="(image, index) in images.slice(
            section.images[0],
            section.images[1]
          )"
          :key="image"
        >
          <img
            class="img-fluid pe-2"
            style="
              max-width: 100%;
              max-height: 100%
              height: auto !important;
              pointer-events: auto !important;
            "
            :src="image.src"
            @click.stop="onImageClicked(image)"
          />
          <div class="row">
            <div class="col-12 mt-2">
              <h5 class="text-left" v-html="section.captions[index]"></h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lightbox-modal">
      <LightboxImage
        @click.stop="onLightboxClicked()"
        :imageSrc="lightboxImageSrc"
        :isVisible="isLightboxVisible"
      />
    </div>
  </div>
</template>

<script>
import LightboxImage from "@/components/LightboxImage.vue";

export default {
  name: "Item Details XL",
  props: ["projectInfo", "showDetails", "images"],
  components: {
    LightboxImage,
  },
  data: function () {
    return {
      sections: this.projectInfo.sections,
      isLightboxVisible: false,
      lightboxImageSrc: null,
    };
  },
  methods: {
    cssProps: function () {
      return {
        "--pointerCursor": "url(" + window.pointerCursor + ") 6 6, pointer",
        "--cameraCursor": "url(" + window.cameraCursor + ") 6 6, pointer",
        "--closeCursor": "url(" + window.closeCursor + ") 6 6, auto",
      };
    },
    onImageClicked(img) {
      this.isLightboxVisible = true;
      this.lightboxImageSrc = img.src;
    },
    onLightboxClicked() {
      this.isLightboxVisible = false;
    },
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "./../assets/scss/_variables.scss";
img:hover {
  cursor: var(--cameraCursor);
}

.lightbox-modal {
  cursor: var(--closeCursor);
}

.btn-hovered {
  background-color: $button-black !important;
  color: white !important;
  cursor: var(--pointerCursor);
}

.sticky-row {
  position: fixed !important;
  top: 0;
  right: 0;
  margin-right: 0;
}

.item-detail {
  cursor: var(--closeCursor);
}
</style>