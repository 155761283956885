<template>
  <div
    class="footer p-4 container-fluid footer-container"
    :style="cssProps"
  >
    <div class="row" :style="{ height: footerHeight }"></div>
    <div class="row">
      <div class="col-12">
        <div class="portfolio-item p-1"></div>
      </div>
    </div>
    <div class="row" >
      <div class="d-flex col-md-6 col-sm-12 nav-item justify-content-start">
        <h4>&#169; Dalma Földesi</h4>
      </div>
      <div class="d-flex col-md-3 col-sm-12 nav-item justify-content-start">
        <h3></h3>
      </div>
      <div :class="justifyDANGClass + ' d-flex col-md-3 col-sm-12 nav-item'">
        <h4>&#9724; 2023</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: [],
  data: function () {
    return {
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      
      justifyDANGClass:
        window.innerWidth < 768
          ? "justify-content-start"
          : "justify-content-end",
    };
  },
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 768) {
        this.justifyDANGClass = "justify-content-start";
      } else {
        this.justifyDANGClass = "justify-content-end";
      }
      
    },
  },
  computed: {
    footerHeight() {
      let footerHeight = window.innerWidth > 768 ? "50vh" : "25vh";

      if (this.$route.name === "Playground") footerHeight = "auto";

      return footerHeight;
    },
    cssProps: function () {
      return {
        "--pointerCursor": "url(" + window.pointerCursor + ") 6 6, pointer",
        "--backgroundColor": (this.$route.name === "Playground")?"#ECE9E2":"white",
      };
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "./../assets/scss/_variables.scss";

a {
  color: black !important;
  text-decoration: none !important;
}

.footer {
  background-color: var(--backgroundColor);

  width: 100vw;

  z-index:0 !important;
}

.footer-item {
  font-family: "PP Neue Machina";
  text-transform: uppercase;
}
.footer-item a:hover {
  /* text-decoration: underline; */
  /* border-bottom: 1px solid white;
    display: inline-block;
    line-height: 0.85; */
  color: black;
  text-decoration: underline !important;
  cursor: var(--pointerCursor);
}
a router-link-active {
  text-decoration: none !important;
  color: black;
}

.portfolio-item {
  border-top: 1px solid black;
}
</style>