<template>
  <div class="outer-container">
    <div class="container-fluid sticky sticky-top p-3 m-2">
      <div class="row">
        <div class="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-6">
          <h2 class="mt-0 mb-4 ml-0 pb-4 pl-0 play-title">
            {{ this.projectInfo.title }}
          </h2>
        </div>
        <div
          class="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-6 justify-content-end d-flex stick-button sticky sticky-top"
        >
          <button
            type="button"
            data-mdb-sticky-boundary="true"
            class="btn float-right mt-0 me-0 play-btn"
            @click="onCloseClicked()"
          >
            CLOSE &#8598;
          </button>
        </div>
      </div>
    </div>
    <div class="play-popup">
      <div class="container-fluid align-items-end play-inner p-3 m-2">
        <div class="row mt-3 pe-0 top-row">
          <div
            class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-xs-12 justify-content-start"
          >
            <div class="row">
              <div class="col-lg-6 col-xl-6 col-md-4 col-sm-12">
                <h2
                  class="mb-4"
                  v-html="this.projectInfo.shortDescription"
                ></h2>
              </div>
              <div class="col-lg-6 col-xl-6 col-md-8 col-sm-12 mb-2">
                <h3 v-html="this.projectInfo.longDescription"></h3>
              </div>
            </div>
          </div>
        </div>
        <div v-for="section in sections" :key="section.title">
          <div class="row mt-4 pt-4">
            <div class="col-12 col-sm-12 col-md-6">
              <h4 style="text-transform: uppercase">{{ section.title }}</h4>
              <h3>{{ section.description }}</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-12 col-xl-8 mt-1">
              <div
                class="pt-0 pb-2 mt-2"
                v-for="item in section.media"
                :key="item"
              >
                <PlayVideo
                  v-if="String(item.url).includes('vimeo')"
                  :video="item"
                  :isThumbnail="false"
                />
                <PlayImage v-else :url="item.url" />
              </div>
            </div>
          </div>
        </div>
        <div class="lightbox-modal">
          <LightboxImage
            @click.stop="onLightboxClicked()"
            :imageSrc="lightboxImageSrc"
            :isVisible="isLightboxVisible"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LightboxImage from "@/components/LightboxImage.vue";
import PlayVideo from "@/components/PlayVideo.vue";
import PlayImage from "@/components/PlayImage.vue";

export default {
  name: "Play Details Lightbox",
  props: ["projectInfo", "showDetails", "media"],
  components: {
    LightboxImage,
    PlayVideo,
    PlayImage,
  },
  data: function () {
    return {
      sections: this.projectInfo.sections,
      isLightboxVisible: false,
      lightboxImageSrc: null,
    };
  },
  methods: {
    cssProps: function () {
      return {
        "--pointerCursor": "url(" + window.pointerCursor + ") 6 6, pointer",
        "--cameraCursor": "url(" + window.cameraCursor + ") 6 6, pointer",
        "--closeCursor": "url(" + window.closeCursor + ") 6 6, auto",
      };
    },
    onImageClicked(img) {
      this.isLightboxVisible = true;
      this.lightboxImageSrc = img.src;
    },
    onLightboxClicked() {
      this.isLightboxVisible = false;
    },
    onCloseClicked() {
      this.$parent.showDetails = false;
    },
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./../assets/scss/_variables.scss";
img:hover {
  cursor: var(--cameraCursor);
}

// https://stackoverflow.com/questions/60162891/clip-scrollbar-inside-rounded-div-corners-css

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(38, 36, 36, 0.3);
  background-color: transparent;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 6px #f8f5f1;
  background-color: #d6d1cb;
  height: 1rem;
}

.lightbox-modal {
  cursor: var(--closeCursor);
}

.lightbox-container {
  cursor: var(--closeCursor);
}

.btn-hovered {
  background-color: $button-black !important;
  color: white !important;
  cursor: var(--pointerCursor);
}

.sticky-row {
  position: fixed !important;
  top: 0;
  right: 0;
  margin-right: 0;
}

.exampleModalCenterodal {
  z-index: 50000;
}

.play-popup {
  top: 0;
  left: 0;

  overflow-x: clip;
  overflow-y: auto;
  // overflow: auto;
}

.stick-button {
  position: sticky;
}

.outer-container {
  top: 15vh;
  left: 7.5vw;
  height: 80vh;
  width: 85vw;

  max-width: 85vw;

  z-index: 50000 !important;
  position: fixed;

  background: #f8f5f1;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 15px;

  overflow-x: hidden;
  overflow-y: scroll;
  border-radius: 10px;

  pointer-events: all !important;

  cursor: var(--closeCursor) !important;

  // padding: 15px;
}

.play-title {
  text-transform: uppercase;
}
</style>