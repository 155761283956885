<script setup>
import { ref, watch } from "vue";
import store from "@/store/index";

const castRangeToNumber = (node) => {
  // We add a check to add the cast only to range inputs
  if (node.props.type !== "range") return;

  node.hook.input((value, next) => next(Number(value)));
};

const value = ref(5);

/**
 * Background color
 */
const backgroundColor = ref(
  store.state.guiParams.sceneSettings.backgroundColor
);

watch(backgroundColor, (newX) => {
//   console.log(`backgroundColor is ${newX}`);
  let payload = {
    key: "backgroundColor",
    val: newX,
  };
  store.commit("updateGuiParam", payload);
});

/**
 * Gravity
 */
const gravityVal = ref(
  store.state.guiParams.sketchSettings.gravity * (-1.0)
);

watch(gravityVal, (newX) => {
  console.log(`gravity is ${newX}`);
  let payload = {
    key: "gravity",
    val: newX * (-1.0),
  };
  store.commit("updateGuiParam", payload);
});
</script>

<template>
  <div class="container-fluid mt-4">
    <!-- <div>
      <h4 class="form-label">Adding the createCharacter submit handler</h4>
    </div> -->

    <!-- form is also an input, so it also accepts plugins -->
    <FormKit
      type="form"
      submit-label=" "
      :plugins="[castRangeToNumber]"
      :default="{ value }"
      outer-class="test-text"
      inner-class="p-2 m-2 justify-content-start d-flex text-left"
      :wrapper-class="{
        'mb-3': true,
        'p-2': true,
        'justify-content-start': true,
        'd-flex': true,
        'test-text': true,
      }"
    >

      <FormKit
        type="select"
        label="View"
        name="view"
        id="view"
        placeholder="PERSPECTIVE"
        :options="['PERSPECTIVE', 'PLAN']"
      />

      <FormKit
        type="color"
        :value="{ backgroundColor }"
        label="Background color"
        v-model="backgroundColor"
      />

      <FormKit
        type="range"
        name="gravity"
        id="gravity"
        label="Gravity"
        :value="{gravityVal}"
        min="0.00"
        max="1.50"
        step="0.01"
        v-model="gravityVal"
      />

      <!-- <pre wrap>{{ value }}</pre> -->
    </FormKit>

  </div>
</template>

<style>

.label {
    text-align: left;
}


.formkit-input {
    border: none;
}

.formkit-wrapper {
    margin-bottom: 15px;
    text-align: left;

    padding: 10px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
}

input.formkit-input {
    background: transparent !important;
    border: none !important;
}

.formkit-actions .formkit-outer .formkit-wrapper {
    background: transparent !important;
}

/** 
INPUT RANGE 
https://www.cssportal.com/style-input-range/
*/
input[type=range] {
  height: 25px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000;
  background: #E3DCE0;
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #2497E3;
  height: 18px;
  width: 18px;
  border-radius: 28px;
  background: #000000;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #E3DCE0;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #E3DCE0;
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #2497E3;
  height: 18px;
  width: 18px;
  border-radius: 28px;
  background: #000000;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #E3DCE0;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-fill-upper {
  background: #E3DCE0;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #2497E3;
  height: 18px;
  width: 18px;
  border-radius: 28px;
  background: #000000;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #E3DCE0;
}
input[type=range]:focus::-ms-fill-upper {
  background: #E3DCE0;
}

</style>

