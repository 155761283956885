<template>
  <div class="product" :style="cssProps">
    <Navbar />
    
    <div class="project-index">
      <div style="height: 30vh"></div>
      <div class="container d-sm-none p-4 pb-1">
        <div class="d-flex col-md-4 col-sm-12">
          <h2>
            &#9749;
            CASE STUDIES &#8595;
          </h2>
        </div>
      </div>
      <div v-for="(project, index) in filteredProjects" :key="project">
        <PortfolioItemXL :projectData="project" :index="index+1" />
      </div>
      <div class="container-fluid p-4 pb-0 pt-2">
        <div class="row">
          <div class="col-12">
            <div class="portfolio-item p-1"></div>
          </div>
        </div>
      </div>
      <SideBar 
          title="About" 
          offset="0"
          z="5000"
          sidebarOpen="false"
        />
      <div
        class="tooltip-div"
        v-if="windowWidth > 768"
        :style="{
          pointerEvents: 'all',
          position: 'absolute',
          left: mouseX + 25 + 'px',
          top: mouseY + 25 + 'px',
          zIndex: 5000,
          maxWidth: '20vw',
        }"
      >
        <h4>{{ mouseX }}, {{ mouseY }}</h4>
      </div>
      <Footer/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import PortfolioItemXL from "@/components/PortfolioItemXL.vue";
import SideBar from "@/components/SideBar.vue";
import Footer from "@/components/Footer.vue";


export default {
  name: "Product View",
  components: {
    Navbar,
    PortfolioItemXL,
    SideBar,
    Footer
  },
  data: function () {
    return {
      filteredProjects: null,
      userFilter: "",
      mouseX: -1,
      mouseY: -1,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    projects() {
      return this.$store.state.products;
    },
    cssProps: function () {
      return {
        "--pointerCursor": "url(" + window.pointerCursor + ") 6 6, pointer",
        "--arrowCursor": "url(" + window.arrowCursor + ") 6 6, auto",
      };
    },
  },
  watch: {},
  methods: {
    updateFilter() {
      let curFilter = this.userFilter;
      console.log(curFilter);
      if (curFilter == "") {
        console.log("empty filter");
        this.filteredProjects = this.projects;
        return;
      }

      this.filteredProjects = this.projects.filter(function (obj) {
        let tagList = obj["Tags"].toLowerCase().trim().split(",");
        tagList = tagList.map((x) => x.trim());
        console.log(tagList);
        return tagList.includes(curFilter);
      });
    },
    onMouseMove: function (event) {
      this.mouseX = event.pageX;
      this.mouseY = event.pageY;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  mounted() {
    document.addEventListener("mousemove", this.onMouseMove);
    window.addEventListener("resize", this.onResize);

    this.filteredProjects = this.projects;
  },
};
</script>

<style lang="scss">
.home {
  cursor: var(--arrowCursor);
  // scroll-margin-top: 80px;
}

::-webkit-scrollbar {
  width: 1px;
}
::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: transparent;
  border-radius: 1rem;
}
::-webkit-scrollbar-thumb {
  // border-radius: 1rem;
  // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
  background-color: black;
  height: 1rem;
}

.circle {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 1.25rem;
  font-size: 0.75rem;
  color: #fff;
  line-height: 1.4rem;
  text-align: top;
  background: #000;
  cursor: var(--pointerCursor);
}

.hello-text {
  position: absolute;

  top: var(--navbarHeight);
  left: 0px;
}

</style>