<template>
  <div class="iframe-container container-fluid p-0 m-0 align-items-center" :style="cssVars">
    <iframe
      :src="url"
      :width="this.videoWidth"
      :height="this.videoHeight"
      frameborder="0"
      allow="autoplay; fullscreen"
      autoplay="true"
      loop="true"
    >
    </iframe>
  </div>
</template>


<script>
export default {
  name: "PlayVideo",
  props: ["video", "isThumbnail"],
  components: {},
  data: function () {
    return {
      videoWidth: 0,
      videoHeight: 0,
    };
  },
  methods: {
    mapRange(value, low1, high1, low2, high2) {
      return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1);
    },
    onWindowResize() {
      this.videoWidth =
        this.$parent.$el.offsetWidth || this.fullWidth;
      this.videoHeight =
        this.$parent.$el.offsetWidth * this.aspect ||
        this.fullHeight;

      // console.log(this.$parent.$el.parentElement.offsetWidth)
    },
  },
  computed: {
    fullWidth() {
      if (window.innerWidth < 600 && this.isThumbnail) {
        return this.video.dimensions[0] * 0.2
      }
      return this.isThumbnail
        ? this.video.dimensions[0] * 0.4
        : this.video.dimensions[0];
    },
    fullHeight() {
      if (window.innerWidth < 600 & this.isThumbnail) {
        return this.video.dimensions[1] * 0.2
      }
      return this.isThumbnail
        ? this.video.dimensions[1] * 0.4
        : this.video.dimensions[1];
    },
    url() {
      return this.video.url;
    },
    aspect() {
      return this.fullHeight / this.fullWidth;
    },
    cssVars() {
      return {
        "--halfWidth": (this.fullWidth * 0.3) * -0.5 + "px",
        "--width": this.fullWidth + "px",
        "--height": this.fullHeight + "px",
      }
    }
  },
  mounted() {
    this.videoWidth =
      this.$parent.$el.offsetWidth - this.$el.style.padding || this.fullWidth;
    this.videoHeight =
      this.$parent.$el.offsetWidth * this.aspect || this.fullHeight;

    window.addEventListener("resize", this.onWindowResize);
  },
};
</script>

<style scoped>
.iframe-container {
  /* width: 100%;
  height: 100%; */

  max-width: 100% !important;

  text-align: center;

  display: block;
  margin: auto;
}

iframe {
  position: relative;
  left: 0;
  top: 0;

  display: block;
  margin: auto;
}
</style>