<template>
  <div class="row p-3 align-items-end top-ro sticky-top">
    <div class="row mt-3 pe-0">
      <div class="col-lg-10 col-xl-10 col-md-8 col-sm-8 col-8 justify-content-start">
        <div class="row">
          <h3 class="mt-0 mb-1 ml-0 pb-1 pl-0 play-title">
            {{ this.projectInfo.title }}
          </h3>
        </div>
        <div class="row">
          <div class="col-12 col-md-8">
            <h3
              class="mt-1 mb-4"
              v-html="this.projectInfo.shortDescription"
            ></h3>
          </div>
        </div>
      </div>
      <div
        class="col-lg-2 col-xl-2 col-md-4 col-sm-4 col-4 justify-content-end d-flex stick-button"
      >
        <button
          type="button"
          class="btn float-right mt-0 me-0 play-btn"
          @click="onCloseClicked()"
        >
          CLOSE &#8598;
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["projectInfo"],
  data: function () {
    return {};
  },
  methods: {
    onCloseClicked() {
      this.$parent.$parent.showDetails = false;
    },
  },
};
</script>

<style scoped>
.top-row {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);

  position: sticky;

  overflow: hidden;

}

.play-title {
  text-transform: uppercase;
}


</style>