<template>
  <nav
    class="navbar fixed-top p-4 w-100"
    :style="cssProps"
  >
    <div class="row nav-row" >
      <div class="d-flex col-md-3 col-sm-12 nav-item justify-content-start">
        <h2>
          &#11044;
          <router-link to="/">Dalma Földesi</router-link>
        </h2>
      </div>
      <div class="d-flex col-md-3 col-sm-12 nav-item justify-content-start">
        <h2>
          &#9650;
          <a
            @click="onInfoClicked()"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasId"
            aria-controls="offcanvasId"
            >Info</a
          >,
          <!-- <a href="mailto:">Email</a> -->
        </h2>
      </div>
      <div class="d-flex col-md-3 col-sm-12 nav-item justify-content-start">
        <h2>
          &#10042;
          <router-link to="/play">Play</router-link>,
          <router-link to="/">Index</router-link>
        </h2>
      </div>
      <div :class="justifyDANGClass + ' d-flex col-md-3 col-sm-12 nav-item'">
        <h2>
          &#9724;
          <a href="https://hybrid-objects.com" target="_blank"
            >Hybrid Objects</a
          >
          &#8599;
        </h2>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  props: ["name"],
  data: function () {
    return {
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      bNarrowScreen: false,
      navbarHeight:
        window.innerWidth > 768
          ? window.navbarDims[1] + "px"
          : window.navbarDims[0] + "px",
      justifyDANGClass:
        window.innerWidth < 768
          ? "justify-content-start"
          : "justify-content-end",
    };
  },
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 768) {
        this.bNarrowScreen = true;
        this.justifyDANGClass = "justify-content-start";
      } else {
        this.bNarrowScreen = false;
        this.justifyDANGClass = "justify-content-end";
      }
      this.navbarHeight =
        window.innerWidth > 768
          ? window.navbarDims[1] + "px"
          : window.navbarDims[0] + "px";
    },
    onInfoClicked() {
      // console.log("Info clicked.");
    },
  },
  computed: {
    cssProps: function () {
      return {
        "--height": this.navbarHeight + "px",
        "--pointerCursor": "url(" + window.pointerCursor + ") 6 6, pointer",
        "--backgroundColor": (this.$route.name === "Playground")?"#ECE9E2":"#FFFFFF",
      };
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "./../assets/scss/_variables.scss";

a {
  color: black !important;
  text-decoration: none !important;
}
.navbar {

  height: var(--navbarHeight);

  width: 100%;

  background-color: var(--backgroundColor);

  // backdrop-filter: blur(10px);

  // -webkit-box-shadow: 0 10px 6px -6px #d0d0d0;
  //    -moz-box-shadow: 0 10px 6px -6px #d0d0d0;
  //         box-shadow: 0 10px 6px -6px #d0d0d0;

  z-index: 10000;

  
}

.nav-row {
  width: 100vw;
}
.nav-item {
  font-family: "PP Neue Machina";
  text-transform: uppercase;
}
.nav-item a:hover {
  /* text-decoration: underline; */
  /* border-bottom: 1px solid white;
    display: inline-block;
    line-height: 0.85; */
  color: black;
  text-decoration: underline !important;
  cursor: var(--pointerCursor);
}
a .router-link-active {
  text-decoration: underline !important;
  color: black;
}
</style>