/**
 * Soft body collisions based on 
 * https://github.com/mrdoob/three.js/blob/master/examples/physics_ammo_volume.html
 * 
 * Loading WASM
 * https://github.com/gtalarico/vue-threejs-rhino-demo/blob/master/src/rhinoService.js
 */

import * as THREE from "three";
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import store from "../../store";
// import window.Ammo from 'three/examples/jsm/libs/ammo.wasm.js';
// import window.Ammo from "@/graphics/three-helpers/libs/ammo.wasm.js";

class SoftSerifManager {
    /**
     * Constructor
     * @param {*} ssParams 
     */
    constructor(ssParams) {
        console.log(ssParams);

        this.scene = ssParams.scene;
        this.renderer = ssParams.renderer;
        this.cameraManager = ssParams.cameraManager;

        this.ballMaterial = new THREE.MeshPhongMaterial({ color: 0x202060 });
        this.pos = new THREE.Vector3();
        this.quat = new THREE.Quaternion();

        // Physics variables
        this.gravityConstant = store.state.guiParams.sketchSettings.gravity;
        this.physicsWorld;
        this.rigidBodies = [];
        this.softBodies = [];
        this.margin = 0.05;
        this.transformAux1;
        this.softBodyHelpers;

        this.clock = new THREE.Clock();

        this.loadAmmo();

        this.ammoLoaded = false;

    }

    isAmmoLoaded() {
        return this.ammoLoaded;
    }

    async loadAmmo() {
        // const { window.AmmoInstance } = await new window.Ammo();
        // console.log(ammoInstance)
        await this.initAmmo();

        this.ammoLoaded = true;
        console.log("[ammo] ammo loaded")

        this.init();
        // this.animate();

    }

    async initAmmo() {
        return new Promise((resolve, reject) => {
            const scripts = [
              './wasm/ammo.wasm.js',
            ]
            let loaded = 0
        
            for (let url of scripts) {
              let script = document.createElement('script')
              script.src = url
              document.head.appendChild(script)
              script.onerror = (err) => {
                return reject(err)
              }
              script.onload = () => {
                loaded++
                if (loaded === scripts.length) {
                  window.Ammo()
                    .then(module => {
                      window.Ammo = module
                      resolve()
                    })
                }
              }
            }
          })
    }

    init() {

        this.initPhysics();

        this.createObjects();

    }

    updateGravity(newVal) {
        this.gravityConstant = newVal;

        console.log(newVal)

        this.physicsWorld.setGravity(new window.Ammo.btVector3(0, this.gravityConstant, 0));
        this.physicsWorld.getWorldInfo().set_m_gravity(new window.Ammo.btVector3(0, this.gravityConstant, 0));
    }

    initPhysics() {

        // Physics configuration

        const collisionConfiguration = new window.Ammo.btSoftBodyRigidBodyCollisionConfiguration();
        const dispatcher = new window.Ammo.btCollisionDispatcher(collisionConfiguration);
        const broadphase = new window.Ammo.btDbvtBroadphase();
        const solver = new window.Ammo.btSequentialImpulseConstraintSolver();
        const softBodySolver = new window.Ammo.btDefaultSoftBodySolver();
        this.physicsWorld = new window.Ammo.btSoftRigidDynamicsWorld(dispatcher, broadphase, solver, collisionConfiguration, softBodySolver);

        this.physicsWorld.setGravity(new window.Ammo.btVector3(0, this.gravityConstant, 0));
        this.physicsWorld.getWorldInfo().set_m_gravity(new window.Ammo.btVector3(0, this.gravityConstant, 0));

        this.transformAux1 = new window.Ammo.btTransform();
        this.softBodyHelpers = new window.Ammo.btSoftBodyHelpers();

    }

    createObjects() {

        // Ground
        this.pos.set(0, - 0.5, 0);
        this.quat.set(0, 0, 0, 1);
        const ground = this.createParalellepiped(80, 1, 80, 0, this.pos, this.quat, new THREE.MeshPhongMaterial({ 
            color: 0xD8B7A6,
            emissive: 0xE0D0C1,
            emissiveIntensity: 0.6
        }));
        ground.castShadow = true;
        ground.receiveShadow = true;

        // this.textureLoader.load('images/grid.png', function (texture) {

        //     texture.wrapS = THREE.RepeatWrapping;
        //     texture.wrapT = THREE.RepeatWrapping;
        //     texture.repeat.set(40, 40);
        //     ground.material.map = texture;
        //     ground.material.needsUpdate = true;

        // });

        // Create soft volumes
        const volumeMass = 12;

        // const sphereGeometry = new THREE.SphereGeometry(1.5, 40, 25);
        const sphereGeometry = new THREE.SphereGeometry(1.5, 60, 40);
        sphereGeometry.translate(-2, 5, 0);
        this.createSoftVolume(sphereGeometry, volumeMass, 250);

        const boxGeometry = new THREE.BoxGeometry(1, 1, 5, 6, 6, 40);
        // const boxGeometry = new THREE.BoxGeometry(1, 1, 5, 4, 4, 20);
        boxGeometry.translate(- 2, 2, 0);
        this.createSoftVolume(boxGeometry, volumeMass, 120);

        const boxGeometryTop = new THREE.BoxGeometry(1, 1, 5, 8, 8, 40);
        boxGeometryTop.translate(- 2, 8, 0);
        this.createSoftVolume(boxGeometryTop, volumeMass, 120);

        // Ramp
        // this.pos.set(3, 1, 0);
        // this.quat.setFromAxisAngle(new THREE.Vector3(0, 0, 1), 30 * Math.PI / 180);
        // const obstacle = this.createParalellepiped(10, 1, 4, 0, this.pos, this.quat, new THREE.MeshPhongMaterial({ color: 0x606060 }));
        // obstacle.castShadow = true;
        // obstacle.receiveShadow = true;

    }

    processGeometry(bufGeometry) {

        // Ony consider the position values when merging the vertices
        const posOnlyBufGeometry = new THREE.BufferGeometry();
        posOnlyBufGeometry.setAttribute('position', bufGeometry.getAttribute('position'));
        posOnlyBufGeometry.setIndex(bufGeometry.getIndex());

        // Merge the vertices so the triangle soup is converted to indexed triangles
        const indexedBufferGeom = BufferGeometryUtils.mergeVertices(posOnlyBufGeometry);

        // Create index arrays mapping the indexed vertices to bufGeometry vertices
        this.mapIndices(bufGeometry, indexedBufferGeom);

    }

    mapIndices(bufGeometry, indexedBufferGeom) {

        // Creates window.AmmoVertices, window.AmmoIndices and window.AmmoIndexAssociation in bufGeometry

        const vertices = bufGeometry.attributes.position.array;
        const idxVertices = indexedBufferGeom.attributes.position.array;
        const indices = indexedBufferGeom.index.array;

        const numIdxVertices = idxVertices.length / 3;
        const numVertices = vertices.length / 3;

        bufGeometry.ammoVertices = idxVertices;
        bufGeometry.ammoIndices = indices;
        bufGeometry.ammoIndexAssociation = [];

        for (let i = 0; i < numIdxVertices; i++) {

            const association = [];
            bufGeometry.ammoIndexAssociation.push(association);

            const i3 = i * 3;

            for (let j = 0; j < numVertices; j++) {

                const j3 = j * 3;
                if (this.isEqual(idxVertices[i3], idxVertices[i3 + 1], idxVertices[i3 + 2],
                    vertices[j3], vertices[j3 + 1], vertices[j3 + 2])) {

                    association.push(j3);

                }

            }

        }

    }

    createSoftVolume(bufferGeom, mass, pressure) {

        this.processGeometry(bufferGeom);

        const volume = new THREE.Mesh(bufferGeom, new THREE.MeshPhongMaterial({ 
            color: 0x8E8EED,
            shininess: 0.1,
            emissive: 0x7474ED,
            emissiveIntensity: 0.5,
            wireframe: true,
        }));
        volume.castShadow = true;
        volume.receiveShadow = true;
        volume.frustumCulled = false;
        this.scene.add(volume);

        // this.textureLoader.load( 'textures/colors.png', function ( texture ) {

        //     volume.material.map = texture;
        //     volume.material.needsUpdate = true;

        // } );

        // Volume physic object

        const volumeSoftBody = this.softBodyHelpers.CreateFromTriMesh(
            this.physicsWorld.getWorldInfo(),
            bufferGeom.ammoVertices,
            bufferGeom.ammoIndices,
            bufferGeom.ammoIndices.length / 3,
            true);

        let numIterations = 40;
        const sbConfig = volumeSoftBody.get_m_cfg();
        sbConfig.set_viterations(numIterations);
        sbConfig.set_piterations(numIterations);

        // Soft-soft and soft-rigid collisions
        sbConfig.set_collisions(0x11);

        // Friction
        sbConfig.set_kDF(0.1);
        // Damping
        sbConfig.set_kDP(0.01);
        // Pressure
        sbConfig.set_kPR(pressure);
        // Stiffness
        let stiffness = 0.3; //0.9
        volumeSoftBody.get_m_materials().at(0).set_m_kLST(stiffness);
        volumeSoftBody.get_m_materials().at(0).set_m_kAST(stiffness);

        volumeSoftBody.setTotalMass(mass, false);
        window.Ammo.castObject(volumeSoftBody, window.Ammo.btCollisionObject).getCollisionShape().setMargin(this.margin);
        this.physicsWorld.addSoftBody(volumeSoftBody, 1, - 1);
        volume.userData.physicsBody = volumeSoftBody;
        // Disable deactivation
        volumeSoftBody.setActivationState(4);

        this.softBodies.push(volume);

    }

    createParalellepiped(sx, sy, sz, mass, pos, quat, material) {

        const threeObject = new THREE.Mesh(new THREE.BoxGeometry(sx, sy, sz, 1, 1, 1), material);
        const shape = new window.Ammo.btBoxShape(new window.Ammo.btVector3(sx * 0.5, sy * 0.5, sz * 0.5));
        shape.setMargin(this.margin);

        this.createRigidBody(threeObject, shape, mass, pos, quat);

        return threeObject;

    }

    createRigidBody(threeObject, physicsShape, mass, pos, quat) {

        threeObject.position.copy(pos);
        threeObject.quaternion.copy(quat);

        const transform = new window.Ammo.btTransform();
        transform.setIdentity();
        transform.setOrigin(new window.Ammo.btVector3(pos.x, pos.y, pos.z));
        transform.setRotation(new window.Ammo.btQuaternion(quat.x, quat.y, quat.z, quat.w));
        const motionState = new window.Ammo.btDefaultMotionState(transform);

        const localInertia = new window.Ammo.btVector3(0, 0, 0);
        physicsShape.calculateLocalInertia(mass, localInertia);

        const rbInfo = new window.Ammo.btRigidBodyConstructionInfo(mass, motionState, physicsShape, localInertia);
        const body = new window.Ammo.btRigidBody(rbInfo);

        threeObject.userData.physicsBody = body;

        threeObject.receiveShadow = true;
        threeObject.castShadow = true;

        threeObject.geometry.computeVertexNormals(); // FIX

        this.scene.add(threeObject);

        if (mass > 0) {

            this.rigidBodies.push(threeObject);

            // Disable deactivation
            body.setActivationState(4);

        }

        this.physicsWorld.addRigidBody(body);

        return body;

    }

    isEqual(x1, y1, z1, x2, y2, z2) {

        const delta = 0.000001;
        return Math.abs(x2 - x1) < delta &&
            Math.abs(y2 - y1) < delta &&
            Math.abs(z2 - z1) < delta;

    }

    // animate() {
    //     console.log("animate")
    //     console.log(this.clock)
    //     console.log(this.animate)
    //     window.requestAnimationFrame(this.animate);

    //     this.render();
    // }

    render() {
        const deltaTime = this.clock.getDelta();

        this.updatePhysics(deltaTime);

        this.cameraManager.renderPerspective();
    }

    updatePhysics(deltaTime) {

        // Step world
        this.physicsWorld.stepSimulation(deltaTime, 10);

        // Update soft volumes
        for (let i = 0, il = this.softBodies.length; i < il; i++) {

            const volume = this.softBodies[i];
            const geometry = volume.geometry;
            const softBody = volume.userData.physicsBody;
            const volumePositions = geometry.attributes.position.array;
            const volumeNormals = geometry.attributes.normal.array;
            const association = geometry.ammoIndexAssociation;
            const numVerts = association.length;
            const nodes = softBody.get_m_nodes();
            for (let j = 0; j < numVerts; j++) {

                const node = nodes.at(j);
                const nodePos = node.get_m_x();
                const x = nodePos.x();
                const y = nodePos.y();
                const z = nodePos.z();
                const nodeNormal = node.get_m_n();
                const nx = nodeNormal.x();
                const ny = nodeNormal.y();
                const nz = nodeNormal.z();

                const assocVertex = association[j];

                for (let k = 0, kl = assocVertex.length; k < kl; k++) {

                    let indexVertex = assocVertex[k];
                    volumePositions[indexVertex] = x;
                    volumeNormals[indexVertex] = nx;
                    indexVertex++;
                    volumePositions[indexVertex] = y;
                    volumeNormals[indexVertex] = ny;
                    indexVertex++;
                    volumePositions[indexVertex] = z;
                    volumeNormals[indexVertex] = nz;

                }

            }

            geometry.attributes.position.needsUpdate = true;
            geometry.attributes.normal.needsUpdate = true;

        }

        // Update rigid bodies
        for (let i = 0, il = this.rigidBodies.length; i < il; i++) {

            const objThree = this.rigidBodies[i];
            const objPhys = objThree.userData.physicsBody;
            const ms = objPhys.getMotionState();
            if (ms) {

                ms.getWorldTransform(this.transformAux1);
                const p = this.transformAux1.getOrigin();
                const q = this.transformAux1.getRotation();
                objThree.position.set(p.x(), p.y(), p.z());
                objThree.quaternion.set(q.x(), q.y(), q.z(), q.w());

            }

        }

    }

    update() {

    }

}

export { SoftSerifManager };