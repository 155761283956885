import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import Playground from "../views/Playground.vue";
import Product from "../views/Product.vue";

const routes = [
  {
    path: "/",
    name: "Index",
    component: Home,
  },
  {
    path: "/product",
    name: "Product",
    component: Product,
  },
  {
    path: "/play",
    name: "Playground",
    component: Playground,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
