/* Imports */
import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.css";

import { plugin, defaultConfig } from '@formkit/vue'

function loadCursors() {
    window.pointerCursor = require("@/assets/icons/pointer.svg");
    window.arrowCursor = require("@/assets/icons/arrow.svg");
    window.cameraCursor = require("@/assets/icons/camera.svg");
    window.waveCursor = require("@/assets/icons/wave.svg");
    window.closeCursor = require("@/assets/icons/close.svg");
    window.dragCursor = require("@/assets/icons/drag.svg");
}


loadCursors();

window.navbarDims = [130, 80];

/* Create Vue Application */
window.app = createApp(App);

/* Attach Router and Vuex Store */
window.app.use(store).use(plugin, defaultConfig).use(router);

/* Mount It */
window.app.mount("#app");





